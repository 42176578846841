import { Col, Row } from "antd";
import React from "react";
import FinderCard from "../../components/FinderCard";
import actionImg from "../../images/action.jpg";
import travelImg from "../../images/travel.jpg";
import droneImg from "../../images/drone.jpg";
import vintageImg from "../../images/vinatge.jpg";
import styles from "../../styles/Search.module.css";

const Visual = () => {
  const Data = [
    { id: 1, headingName: "LUT Name", img: actionImg },
    { id: 2, headingName: "LUT Name", img: actionImg },
    { id: 3, headingName: "LUT Name", img: actionImg },
    { id: 4, headingName: "LUT Name", img: actionImg },
    { id: 5, headingName: "LUT Name", img: actionImg },
    { id: 6, headingName: "LUT Name", img: actionImg },
    { id: 7, headingName: "LUT Name", img: actionImg },
    { id: 8, headingName: "LUT Name", img: actionImg },
  ];
  const DataPacks = [
    { id: 1, headingName: "Action", img: actionImg },
    { id: 2, headingName: "Atmospheric", img: travelImg },
    { id: 3, headingName: "Blues", img: droneImg },
    { id: 4, headingName: "Black and White", img: actionImg },
    { id: 5, headingName: "Blues", img: droneImg },
    { id: 6, headingName: "Cinematic", img: vintageImg },
    { id: 7, headingName: "City", img: actionImg },
    { id: 8, headingName: "Commercial", img: actionImg },
  ];
  return (
    <div style={{ marginBottom: 200 }}>
      <div style={{ marginTop: 20 }}>
        <p className={styles.heading}>LUTS</p>
      </div>
      <Row>
        {Data.map((item) => (
          <Col>
            <FinderCard heading={item.headingName} image={item.img} />
          </Col>
        ))}
      </Row>
      <div className={styles.horizaontalLine}></div>
      <div style={{ marginTop: 20 }}>
        <p className={styles.heading}>Packs</p>
      </div>
      <Row>
        {DataPacks.map((item) => (
          <Col>
            <FinderCard heading={item.headingName} image={item.img} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Visual;
