import React, { useEffect, useState } from "react";
import { Table, message } from "antd";
import {
  MdAddShoppingCart,
  AiOutlineArrowDown,
  MdPlaylistAdd,
  FiSearch,
  RiShareFill,
  BsPlayFill,
} from "react-icons/all";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import AddShoppingCartRoundedIcon from "@mui/icons-material/AddShoppingCartRounded";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import cloneDeep from "lodash.clonedeep";
import { db, auth } from "../firebase";
import PlaylistModal from "./PlaylistModal";
import { useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import DownloadPopup from "./DownloadPopup";
import SharePopup from "./SharePopup";

const CustomTable = (props) => {
  const [open, setOpen] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);
  const [rowRecord, setRowRecord] = useState();
  const [data, setData] = useState([]);
  const [playlistOpen, setPlaylistOpen] = React.useState(false);
  const [createPlaylistOpen, setcreatePlaylistOpen] = React.useState(false);
  const [songId, setSongId] = React.useState();
  const user = useSelector((state) => state.app.user);
  const location = useLocation();
  const history = useHistory();
  const useStyles = makeStyles({
    playbutton: {
      backgroundColor: "rgba(72, 72, 72, 0.24) !important",
      color: "#fff !important",
      "&:hover": {
        color: "#00A3FF !important",
      },
    },
    iconbutton: {
      color: "#fff !important",
      "&:hover": {
        backgroundColor: "rgba(72, 72, 72, 0.24)",
        color: "rgb(0, 163, 255) !important",
      },
    },
  });
  const styleUse = useStyles();

  // const shareTheLink = () => {
  //   navigator
  //     .share({
  //       title: data.soundName ? data.soundName : data.trackName,
  //       text: "A polyfill for the Share API. Use it to share in both desktops and mobile devices.",
  //       url: data.mp3AudioUrl ? data.mp3AudioUrl : data.fullmixmp3,
  //     })
  //     .then((_) => console.log("Yay, you shared it :)"))
  //     .catch((error) => console.log("Oh noh! You couldn't share it! :'(\n", error));
  // };
  console.log(rowRecord);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleOpenShare = () => {
    setOpenShare(true);
  };
  const handleCloseShare = () => setOpenShare(false);
  const columns = [
    {
      title: "Icon",
      dataIndex: "playIcon",
      key: "playIcon",
      width: "2%",
      render: (text, mainval) => (
        <IconButton
          size="medium"
          className={styleUse.playbutton}
          onClick={() => {
            if (props.type === "sfx") {
              window.postMessage({
                type: "playqueue",
                id: mainval.soundId,
                title: mainval.soundName,
                artist: mainval.artistName,
                category: mainval.category,
                fullmix: mainval.mp3AudioUrl,
                audiotype: "sfx",
                image: mainval.image,
                allData: { ...mainval, type: "sfx" },
              });
              // window.postMessage({
              //   type: "playqueue",
              // });
            } else if (props.type == "music") {
              window.postMessage({
                type: "playqueue",
                id: mainval.trackID,
                title: mainval.trackName,
                artist: mainval.artistName,
                category: mainval.category,
                genre: mainval.genre,
                drums: mainval.drumsmp3,
                bass: mainval.bassmp3,
                fullmix: mainval.fullmixmp3,
                inst: mainval.instmp3,
                vocal: mainval.vocalsmp3,
                audiotype: "music",
                image: mainval.image,
                allData: { ...mainval, type: "music" },
              });
            }
          }}
        >
          <PlayArrowRoundedIcon />
        </IconButton>
      ),
    },
    {
      title: "Song",
      dataIndex: props.type === "sfx" ? "soundName" : "trackName",
      key: props.type === "sfx" ? "soundName" : "trackName",
      width: "20%",
      render: (text, secVal) => (
        <div>
          <p
            style={{
              fontSize: 18,
              fontWeight: "400",
              color: "#fff",
              margin: 0,
            }}
          >
            {text}
          </p>
          <p style={{ margin: 0, fontSize: 14 }}>{secVal.artistName}</p>
        </div>
      ),
    },
    {
      className: "txt",
      title: "Mood",
      dataIndex: "mood1",
      key: "mood1",
      width: "20%",
      render: (text, secVal) => (
        <div>
          <span>{text}</span>,<span> {secVal.mood2}</span>
        </div>
      ),
    },
    {
      title: "Genre",
      dataIndex: props.type === "sfx" ? "category" : "genre",
      key: props.type === "sfx" ? "category" : "genre",
      width: "20%",
    },
    {
      title: "Time",
      dataIndex: "bpm",
      key: "bpm",
      width: "20%",
      render: (text) => (text ? <span>{text} BPM </span> : ""),
    },
    {
      title: "Icon",
      dataIndex: "shareIcon",
      key: "shareIcon",
      width: "1%",
      render: (text, record) => (
        <Tooltip
          title="Share"
          onClick={() => {
            if (user) {
              console.log(record);
              setRowRecord({ ...record, type: props.type });
              handleOpenShare();
            } else {
              message.error("Please Login First");
            }
          }}
        >
          <IconButton size="medium" className={styleUse.iconbutton}>
            <ShareRoundedIcon />
          </IconButton>
        </Tooltip>
      ),
      // <RiShareFill className={styleUse.iconbutton} color={"#C4C4C4"} size={24} />,
    },
    {
      title: "Icon",
      dataIndex: props.type === "sfx" ? "soundID" : "trackID",
      key: props.type === "sfx" ? "soundID" : "trackID",
      width: "1%",
      render: (text, record) => (
        <Tooltip
          title="Find Similar"
          onClick={() =>
            history.push({ pathname: `/song/${props.type}/${text}`, state: { record: record, type: props.type } })
          }
        >
          <IconButton size="medium" className={styleUse.iconbutton}>
            <SearchRoundedIcon />
          </IconButton>
        </Tooltip>
      ),

      // <FiSearch className={styleUse.iconbutton} color={"#C4C4C4"} size={24} />,
    },
    {
      title: "Icon",
      dataIndex: props.type === "sfx" ? "soundID" : "trackID",
      key: props.type === "sfx" ? "soundID" : "trackID",
      width: "1%",
      render: (text) => (
        <Tooltip
          title="Add Playlist"
          onClick={() => {
            if (user) {
              setSongId(text);
              setPlaylistOpen(true);
            } else {
              message.error("Please Login First");
            }
          }}
        >
          <IconButton size="medium" className={styleUse.iconbutton}>
            <PlaylistAddRoundedIcon />
          </IconButton>
        </Tooltip>
      ),

      // <MdPlaylistAdd className={styleUse.iconbutton} color={"#C4C4C4"} size={24} />,
    },
    {
      title: "Icon",
      dataIndex: props.type === "sfx" ? "mp3AudioUrl" : "fullmixmp3",
      key: props.type === "sfx" ? "mp3AudioUrl" : "fullmixmp3",
      width: "1%",
      render: (text, record) => (
        <a
          // href={text}
          color="transparent"
          // target="_blank"
          onClick={() => {
            if (user) {
              console.log(record);
              setRowRecord({ ...record, type: props.type });
              handleOpen();
            } else {
              message.error("Please Login First");
            }
          }}
          // download
          // onClick={async () =>
          //   props.type === "sfx" ? sfxUpdateData(secVal.id, secVal.soundID) : musicUpdateData(secVal.id, secVal.trackID)
          // }
        >
          <Tooltip title="Download">
            <IconButton size="medium" className={styleUse.iconbutton}>
              <ArrowDownwardRoundedIcon />
            </IconButton>
          </Tooltip>
        </a>
      ),

      // <AiOutlineArrowDown className={styleUse.iconbutton} color={"#C4C4C4"} size={24} />,
    },
  ];

  useEffect(() => {
    if (location.pathname === "/sfx") {
      setData(props.data);
    } else {
      if (props.data) setData([...props.data.map((item, index) => ({ ...item, key: index }))]);
    }
  }, [props.data]);
  console.log(props.data);
  return (
    <div>
      <Table
        pagination={props.pagination}
        columns={columns}
        dataSource={data}
        showHeader={false}
        scroll={{ x: true }}
      />
      {user ? (
        <>
          <Modal
            open={openShare}
            onClose={handleCloseShare}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <SharePopup type={props.type} RowRecord={rowRecord} />
          </Modal>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <DownloadPopup data={rowRecord} />
          </Modal>{" "}
          <PlaylistModal
            isaddPlaylist={true}
            onClose={() => setPlaylistOpen(false)}
            onOpen={playlistOpen}
            songId={songId}
            type={props.type}
            isChange={() => {
              setPlaylistOpen(false);
              setcreatePlaylistOpen(true);
            }}
          />
          <PlaylistModal
            onOpen={createPlaylistOpen}
            onClose={() => setcreatePlaylistOpen(false)}
            isCreatePlaylist={true}
            songId={songId}
            type={props.type}
          />{" "}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default CustomTable;
