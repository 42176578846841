import React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import styles from "../styles/SongFinder.module.css";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import _ from "lodash";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
// const songs = [
//   { genre: "Rock", mood: "Happy" },
//   { genre: "Country", mood: "Sad" },
//   { genre: "Blues", mood: "Angry" },
//   { genre: "Classical", mood: "Mysterious" },
//   { genre: "Hip Hop", mood: "Chilled" },
//   { genre: "Lo-Fi", mood: "Nostalgic" },
//   { genre: "Rock", mood: "Old School" },
//   { genre: "Soul", mood: "Intimate" },
//   { genre: "Electronic", mood: "Confused" },
//   { genre: "Reggae", mood: "Furious" },
// ];
const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#565656",
    },
    "&:hover fieldset": {
      borderColor: "#565656",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#009AFA",
    },
  },
});
const CustomSelect = (props) => {
  const sfx = useSelector((state) => state.app.sfx);
  const music = useSelector((state) => state.app.music);
  const location = useLocation();
  const [selectedGenre, setSelectedGenre] = React.useState([]);
  const [songs, setSound] = React.useState([]);

  React.useEffect(() => {
    let tempSongs = [];
    if (location.pathname == "/sfx") {
      sfx.map((category) => {
        tempSongs.push({ genre: category.category });
        category.sounds.map((item) => {});
      });
    } else {
      music.map((album) => {
        album.tracks.map((track) => {
          tempSongs.push({ genre: track.genre });
        });
      });
      tempSongs = _.uniqBy(tempSongs, "genre");
    }

    setSound(tempSongs);
  }, []);

  // songs = songs.filter((item) => !selectedGenre.includes(item.genre));

  return (
    <div>
      <div>
        <p className={styles.cardTxt}>Select or Search Genres</p>

        <Autocomplete
          name="Genres"
          multiple
          disableCloseOnSelect
          onChange={(ev, value) => {
            // setSelectedGenre(value.map((item) => item.genre));
            props.handleChange(ev, value);
          }}
          size="medium"
          options={songs}
          // defaultValue={props.selected ? [props.selected] : []}
          getOptionLabel={(option) => option.genre}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
              {option.genre}
            </li>
          )}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <CssTextField
              {...params}
              id="Genres"
              InputLabelProps={{ style: { color: "#C4C4C4" } }}
              label="Genres"
              placeholder="Start Typing"
            />
          )}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <p className={styles.cardTxt} style={{ marginTop: 10 }}>
          Select or Search Moods
        </p>

        <Autocomplete
          name="Moods"
          onChange={(ev, value) => {
            props.handleMood(ev, value);
          }}
          multiple
          size="medium"
          options={props.moods}
          disableCloseOnSelect
          getOptionLabel={(option) => option.mood}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
              {option.mood}
            </li>
          )}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <CssTextField
              {...params}
              InputLabelProps={{ style: { color: "#C4C4C4" } }}
              label="Moods"
              id="Moods"
              placeholder="Start Typing"
            />
          )}
        />
      </div>
    </div>
  );
};

export default CustomSelect;
