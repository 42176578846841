import React from "react";
import CustomTable from "../../components/CustomTable";
import featuredStyles from "../../styles/Featured.module.css";
import lutStyles from "../../styles/LUTFinder.module.css";
import { Col, Row, Modal, Button, Slider } from "antd";
import { AiOutlineArrowLeft, AiOutlineArrowDown } from "react-icons/ai";
import SongCard from "../../components/SongCard";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { db } from "../../firebase";

const SingleSong = (props) => {
  const [music, setMusic] = React.useState([]);
  const [sfxCategory, setSfxCategory] = React.useState([]);
  const [songId, setSongId] = React.useState();
  const [data, setData] = React.useState();
  const [tableData, setTableData] = React.useState([]);
  console.log(props.location.state);
  const location = useLocation();
  const history = useHistory();
  const fetchMusic = async () => {
    let newData = [];
    try {
      db.collection("music")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push({
              id: doc.id,
              albumName: itemData.albumName,
              artistName: itemData.artistName,
              albumImage: itemData.albumImage,
              numberTracks: itemData.numberTracks,
              tracks: itemData.tracks,
            });
          });
          setMusic(newData);
        });
    } catch (err) {
      console.error(err);
    }
  };
  const fetchSounds = async () => {
    let newData = [];
    try {
      db.collection("SFX")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push({
              id: doc.id,
              packName: itemData.packName,
              packImage: itemData.packImage,
              artistName: itemData.artistName,
              numberSounds: itemData.numberSounds,
              category: itemData.category,
              sounds: itemData.sounds,
            });
          });

          setSfxCategory(newData);
        });
    } catch (err) {
      console.error(err);
    }
  };
  React.useEffect(() => {
    if (music.length > 0) {
      let otherSongs = [];
      for (let i = 0; i < music.length; i++) {
        const element = music[i];
        const track = element.tracks.filter((track) => track.trackID === songId);
        if (track.length > 0) {
          music[i].tracks.map((track) => {
            otherSongs.push({
              ...track,
              albumName: element.albumName,
              artistName: element.artistName,
              albumImage: element.albumImage,
              id: element.id,
            });
          });
          setData({
            ...track[0],
            albumName: element.albumName,
            artistName: element.artistName,
            albumImage: element.albumImage,
          });
        }
      }
      setTableData(otherSongs);
    } else if (sfxCategory.length > 0) {
      let otherSongs = [];
      for (let i = 0; i < sfxCategory.length; i++) {
        const element = sfxCategory[i];
        const sound = element.sounds.filter((sound) => sound.soundID === songId);
        if (sound.length > 0) {
          sfxCategory[i].sounds.map((sound) => {
            otherSongs.push({
              ...sound,
              category: element.category,
              artistName: element.artistName,
              image: element.packImage,
              id: element.id,
            });
          });
          setData({
            ...sound[0],
            artistName: element.artistName,
            category: element.category,
            packImage: element.packImage,
            id: element.id,
          });
        }
      }
      setTableData(otherSongs);
    }
  }, [sfxCategory, music]);
  const type = location.pathname.split("/")[2];
  React.useEffect(() => {
    setSongId(location.pathname.split("/")[3]);
    if (type === "sfx") {
      fetchSounds();
    } else if (type === "music") {
      fetchMusic();
    }
  }, []);
  console.log(data);
  return (
    <div className={featuredStyles.maincontainer}>
      <Row justify="center" style={{ marginTop: 20 }}>
        <Col span={18}>
          <div style={{ marginTop: 30 }}>
            <SongCard rowData={props.location.state} data={data ? data : ""} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <p className={lutStyles.heading}>Similar Songs</p>
            <Button
              type="primary"
              size={"large"}
              onClick={() => history.goBack()}
              className={lutStyles.btnStyle}
              style={{ marginLeft: 20 }}
            >
              <AiOutlineArrowLeft size={18} color={"#fff"} style={{ marginRight: 10 }} />
              Back to Featured
            </Button>
          </div>
          <div style={{ marginTop: 20 }} className={featuredStyles.songtable}>
            <CustomTable data={tableData} type={type} />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default SingleSong;
