import React, { useState, useEffect } from "react";
import { Layout, Slider, Select, Row, Col } from "antd";
import styles from "../../styles/LayoutSide.module.css";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

export default function Bar(props) {
  const { duration, curTime, onTimeUpdate } = props;

  const curPercentage = (curTime / duration) * 100;

  function formatDuration(duration) {
    return moment.duration(duration, "seconds").format("mm:ss", { trim: false });
  }

  function calcClickedTime(e) {
    const clickPositionInPage = e.pageX;
    const bar = document.querySelector(".bar__progress");
    const barStart = bar.getBoundingClientRect().left + window.scrollX;
    const barWidth = bar.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = duration / barWidth;
    return timePerPixel * clickPositionInBar;
  }

  function handleTimeDrag(e) {
    onTimeUpdate(calcClickedTime(e));

    const updateTimeOnMove = (eMove) => {
      onTimeUpdate(calcClickedTime(eMove));
    };

    document.addEventListener("mousemove", updateTimeOnMove);

    document.addEventListener("mouseup", () => {
      document.removeEventListener("mousemove", updateTimeOnMove);
    });
  }
  return (
    <>
      <Col>
        <div>
          <p className={styles.songName}>{formatDuration(curTime)}</p>
        </div>
      </Col>
      <Col>
        <div
          className="bar__progress"
          style={{
            background: `linear-gradient(to right, orange ${curPercentage}%, white 0)`,
          }}
          onMouseDown={(e) => handleTimeDrag(e)}
        >
          <span className="bar__progress__knob" style={{ left: `${curPercentage - 2}%` }} />
        </div>
        {/* <div className={styles.sliderBar}>
          <Slider
            // range={{ draggableTrack: true }}
            max={14}
            value={value}
            onChange={handleTimeDrag}
            tooltipVisible={false}
            trackStyle={{ backgroundColor: "#C4C4C4" }}
          />
        </div> */}
      </Col>
      <Col>
        <div style={{ marginLeft: 10 }}>
          <p className={styles.songName}>{formatDuration(duration)}</p>
        </div>
      </Col>
    </>
  );
}
