import React from "react";
import PastDownloadCard from "../../components/PastDownloadCard";
import TopCard from "../../components/TopCard";
import CustomTable from "../../components/CustomTable";
import _ from "lodash";

const SFX = ({ sfx, setSfxLength }) => {
  // top category start
  let topCategory = [];
  if (sfx.length > 0) {
    for (let i = 0; i < sfx.length; i++) {
      const element = sfx[i];
      let downloads = 0;
      for (let i = 0; i < element.sounds.length; i++) {
        const sound = element.sounds[i];
        downloads = sound.downloads + downloads;
      }
      topCategory.push({ ...element, downloads: downloads });
    }
  }
  topCategory = _.sortBy(topCategory, ["downloads"]).reverse();
  // top category end

  // popular songs start
  let topSongs = [];
  if (sfx.length > 0) {
    sfx.map((category) => {
      category.sounds.map((sound) => {
        topSongs.push({
          ...sound,
          category: category.category,
          artistName: category.artistName,
          image: category.packImage,
          id: category.id,
        });
      });
    });
  }
  topSongs = _.sortBy(topSongs, ["downloads"]).reverse();
  console.log(topSongs);
  // popular songs end

  React.useEffect(() => {
    setSfxLength(topSongs.length);
  }, [topSongs]);
  return (
    <div style={{ marginBottom: 200 }}>
      <div style={{ marginTop: 20 }}>
        {/* <PastDownloadCard category={"Category"} timePeriod={"00:00"} /> */}

        <CustomTable data={topSongs.filter((item, i) => (i < 5 ? item : ""))} pagination={false} type="sfx" />
      </div>
      <div style={{ marginTop: 20 }}>
        <TopCard
          data={topCategory}
          heading={"Packs"}
          musicType1={"Hip Hop"}
          musicType2={"Pop"}
          musicType3={"Cinematic"}
          musicType4={"Electronic"}
          musicType5={"Rock"}
          musicType6={"Ambient"}
          musicType7={"Jazz"}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <TopCard
          data={[]}
          heading={"Playlists"}
          musicType1={"Happy"}
          musicType2={"Sad"}
          musicType3={"Epic"}
          musicType4={"Angry"}
          musicType5={"Confused"}
          musicType6={"Old School"}
          musicType7={"Nostalgic"}
        />
      </div>
    </div>
  );
};

export default SFX;
