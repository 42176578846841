import React, { useState } from "react";
import { Col, Row, Modal, Button, Slider } from "antd";
import styles from "../../styles/LUTFinder.module.css";
import { useHistory } from "react-router-dom";
import { AiOutlineArrowLeft, AiOutlineArrowDown } from "react-icons/ai";
import FinderCard from "../../components/FinderCard";
import actionImg from "../../images/action.jpg";
import LutModal from "../../components/LutModal";

// [
//   { id: 1, headingName: "LUT Name", img: actionImg },
//   { id: 2, headingName: "LUT Name", img: actionImg },
//   { id: 3, headingName: "LUT Name", img: actionImg },
//   { id: 4, headingName: "LUT Name", img: actionImg },
//   { id: 5, headingName: "LUT Name", img: actionImg },
//   { id: 6, headingName: "LUT Name", img: actionImg },
//   { id: 7, headingName: "LUT Name", img: actionImg },
//   { id: 8, headingName: "LUT Name", img: actionImg },
//   { id: 9, headingName: "LUT Name", img: actionImg },
//   { id: 10, headingName: "LUT Name", img: actionImg },
//   { id: 11, headingName: "LUT Name", img: actionImg },
//   { id: 12, headingName: "LUT Name", img: actionImg },
// ];

const LUTView = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeLut, setActiveLut] = useState();
  const Data = props.location.state.luts;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const history = useHistory();
  return (
    <div className={styles.maincontainer}>
    <div style={{ marginBottom: 200 }}>
      <Row justify={"center"}>
        <Col span={20}>
          <div style={{ marginTop: 20 }}>
            <p className={styles.heading}>Action LUTS</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p className={styles.txt}>Click on a LUT to preview it</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {/* <p className={styles.txt}>Sort by: Most Popular</p>
                <AiOutlineArrowDown size={20} color={"#fff"} style={{ marginLeft: 5 }} /> */}
                <Button
                  type="primary"
                  size={"large"}
                  onClick={() => history.goBack()}
                  className={styles.btnStyle}
                  style={{ marginLeft: 20 }}
                >
                  <AiOutlineArrowLeft size={18} color={"#fff"} style={{ marginRight: 10 }} />
                  Back to Categories
                </Button>
              </div>
            </div>
          </div>
          <Row>
            {Data.map((item) => (
              <Col
                onClick={() => {
                  setActiveLut(item);
                  showModal();
                }}
                style={{ cursor: "pointer" }}
              >
                <FinderCard heading={item.lutName} image={item.previewImage} />
              </Col>
            ))}
            {activeLut ? (
              <LutModal
                isModalVisible={isModalVisible}
                packName={props.location.state.packName}
                activeLut={activeLut}
                handleCancel={handleCancel}
              />
            ) : (
              ""
            )}
          </Row>
        </Col>
      </Row>
    </div>
    </div>
  );
};

export default LUTView;
