import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { Route, Switch } from "react-router-dom";
import FeaturedVisual from "./FeaturedVisual";
import LUTFinder from "./LUTFinder";
import LUTView from "./LUTView";
import { db } from "../../firebase";
import { useSelector, useDispatch } from "react-redux";
import { setLutCategory } from "../../redux/actions/appActions";

const { TabPane } = Tabs;

const Visual = () => {
  const [activeKey, setActiveKey] = useState("1");
  const dispatch = useDispatch();

  function callback(key) {
    setActiveKey(key);
  }
  const fetchLuts = async () => {
    let newData = [];
    try {
      db.collection("lut")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push({
              id: doc.id,
              packName: itemData.packName,
              packImage: itemData.packImage,
              beforeImage: itemData.beforeImage,
              numberLut: itemData.numberLut,
              category: itemData.category,
              downloads: itemData.downloads,
              luts: itemData.luts,
            });
          });
          dispatch(setLutCategory(newData));
          console.log(newData, "new data");
        });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchLuts();
  }, []);
  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        activeKey={activeKey}
        onChange={callback}
        centered={true}
        // tabBarGutter={350}
        tabBarStyle={{
          backgroundColor: "#171717",
          color: "#BCBCBC",
        }}
        size={"large"}
      >
        {/* <TabPane tab="Featured" key="1">
          <FeaturedVisual setActiveKey={(item) => setActiveKey(item)} />
        </TabPane> */}
        <TabPane tab="LUT Finder" key="1">
          <Switch>
            <Route exact path="/visual" component={LUTFinder} />
            <Route path="/visual/lutView" component={LUTView} />
          </Switch>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Visual;
