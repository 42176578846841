import React from "react";
import styles from "./Popup.module.css";
import Box from "@mui/material/Box";
import { IconButton, Stack, Tooltip } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Button from "@mui/material/Button";
import { message } from "antd";
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton, MailruShareButton } from "react-share";

import { CopyToClipboard } from "react-copy-to-clipboard";
const SharePopup = (props) => {
  return (
    <div>
      <Box className={styles.boxShare} style={{ width: "100%", maxWidth: "700px" }}>
        <p className={styles.heading}> Share</p>
        <div className={styles.shareContainer} style={{ width: "100%" }}>
          <Stack direction="row" spacing={4} alignItems="center" justifyContent="center">
            <div>
              <p className={styles.shareSmalltxt}>WhatsApp</p>
              <WhatsappShareButton
                url={`https://creatorjams.com/song/${props.type}/${
                  props.type === "sfx" ? props.RowRecord.soundID : props.type === "music" ? props.RowRecord.trackID : ""
                }`}
              >
                <Tooltip title="WhatsApp">
                  <IconButton sx={{ backgroundColor: "#00E676" }}>
                    <WhatsAppIcon sx={{ fontSize: 38, color: "#FFFFFF" }} />
                  </IconButton>
                </Tooltip>
              </WhatsappShareButton>
            </div>
            <div>
              <p className={styles.shareSmalltxt}>Facebook</p>
              <FacebookShareButton
                url={`https://creatorjams.com/song/${props.type}/${
                  props.type === "sfx" ? props.RowRecord.soundID : props.type === "music" ? props.RowRecord.trackID : ""
                }`}
              >
                {" "}
                <Tooltip title="Facbook">
                  <IconButton sx={{ backgroundColor: "#1877F2" }}>
                    <FacebookIcon sx={{ fontSize: 38, color: "#FFFFFF" }} />
                  </IconButton>
                </Tooltip>
              </FacebookShareButton>
            </div>
            <div>
              <p className={styles.shareSmalltxt}>Twitter</p>
              <TwitterShareButton
                url={`https://creatorjams.com/song/${props.type}/${
                  props.type === "sfx" ? props.RowRecord.soundID : props.type === "music" ? props.RowRecord.trackID : ""
                }`}
              >
                <Tooltip title="Twitter">
                  <IconButton sx={{ backgroundColor: "#00ACEE" }}>
                    <TwitterIcon sx={{ fontSize: 38, color: "#FFFFFF" }} />
                  </IconButton>
                </Tooltip>
              </TwitterShareButton>
            </div>
            <div>
              <p className={styles.shareSmalltxt}>Email</p>
              <MailruShareButton
                url={`https://creatorjams.com/song/${props.type}/${
                  props.type === "sfx" ? props.RowRecord.soundID : props.type === "music" ? props.RowRecord.trackID : ""
                }`}
              >
                <Tooltip title="Email">
                  <IconButton sx={{ backgroundColor: "#fff" }}>
                    <MailOutlineIcon sx={{ fontSize: 38, color: "#000000" }} />
                  </IconButton>
                </Tooltip>
              </MailruShareButton>
            </div>
          </Stack>
          <div className={styles.copyContainer} style={{ width: "100%" }}>
            <Stack spacing={2} direction="row" alignItems="center">
              <p className={styles.linktxt}>
                https://creatorjams.com/song/{props.type}/
                {props.type === "sfx" ? props.RowRecord.soundID : props.type === "music" ? props.RowRecord.trackID : ""}
              </p>
              <CopyToClipboard
                text={`https://creatorjams.com/song/${props.type}/${
                  props.type === "sfx" ? props.RowRecord.soundID : props.type === "music" ? props.RowRecord.trackID : ""
                }`}
              >
                <Button variant="text" className={styles.txtBtn} onClick={() => message.success("successfully copy!")}>
                  COPY
                </Button>
              </CopyToClipboard>
            </Stack>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default SharePopup;
