import { Button, Col, Input, Row, Upload } from "antd";
import React from "react";
import styles from "./Track.module.css";


const props = {
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange({ file, fileList }) {
    if (file.status !== "uploading") {
      console.log(file, fileList);
    }
  },
};
const LUT = ({ heading }) => {

  return (
    <div >
      <div className={styles.horizaontalLine}></div>
      <p className={styles.cardHeading}>{heading}</p>
      <div>
        <p className={styles.Cardtxt}>LUT Name</p>
        <Input
          size="large"
          placeholder="LUT Name"
          className={styles.smallinputBox}
        />
      </div>
 
        <Row align={"middle"} style={{ marginTop: 20 }}>
          <Col>
            <p className={styles.Cardtxt}>Select Preview Image*</p>
          </Col>
          <Col style={{ marginLeft: 40 }}>
            <Upload {...props}>
              <Button className={styles.uploadButton}>Browse file...</Button>
            </Upload>
          </Col>
          <Col style={{ marginLeft: 20 }}>
            <p className={styles.Cardtxt}>filename.jpg</p>
          </Col>
        </Row>

        <Row align={"middle"} style={{ marginTop: 20 }}>
          <Col>
            <p className={styles.Cardtxt}>Select After Image*</p>
          </Col>
          <Col style={{ marginLeft: 55 }}>
            <Upload {...props}>
              <Button className={styles.uploadButton}>Browse file...</Button>
            </Upload>
          </Col>
          <Col style={{ marginLeft: 20 }}>
            <p className={styles.Cardtxt}>filename.jpg</p>
          </Col>
        </Row>
        <Row align={"middle"} style={{ marginTop: 20 }}>
          <Col>
            <p className={styles.Cardtxt}>Select LUT File*</p>
          </Col>
          <Col style={{ marginLeft: 85 }}>
            <Upload {...props}>
              <Button className={styles.uploadButton}>Browse file...</Button>
            </Upload>
          </Col>
          <Col style={{ marginLeft: 20 }}>
            <p className={styles.Cardtxt}>filename.cube</p>
          </Col>
        </Row>
    </div>
  );
};

export default LUT;
