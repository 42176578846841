import { SET_USER, SET_LUTS, SET_SFX, SET_MUSIC } from "../constants";

const initState = {
  user: null,
  luts: [],
  sfx: [],
  music: [],
};

const appReducer = function (state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_USER:
      return {
        ...state,
        user: payload,
      };
    case SET_LUTS:
      return {
        ...state,
        luts: payload,
      };
    case SET_SFX:
      return {
        ...state,
        sfx: payload,
      };
    case SET_MUSIC:
      return {
        ...state,
        music: payload,
      };

    default:
      return { ...state };
  }
};

export default appReducer;
