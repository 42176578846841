import React, { useState, useRef } from "react";
import { Layout, Slider, Select, Row, Col } from "antd";
import styles from "../../styles/LayoutSide.module.css";
import musicImg from "../../images/music.jpg";
import Modal from "@mui/material/Modal";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import SkipNextRoundedIcon from "@mui/icons-material/SkipNextRounded";
import AddShoppingCartRoundedIcon from "@mui/icons-material/AddShoppingCartRounded";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import PauseIcon from "@mui/icons-material/Pause";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DownloadPopup from "../../components/DownloadPopup";
import SharePopup from "../../components/SharePopup";
import { maxWidth } from "@mui/system";
import { makeStyles } from "@material-ui/core/styles";
import Bar from "./Bar";
import SoundControler from "./SoundControler";
import UseAudioPlayer from "./UseAudioPlayer";

const { Footer } = Layout;
const { Option } = Select;

export default function Palyer() {
  const {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
    setVolume,
    volume,
    currentModes,
    currentMode,
    setMode,
    currentlyPlaying,
  } = UseAudioPlayer();
  const [open, setOpen] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);
  const handleOpenShare = () => {
    if (currentlyPlaying.allData) setOpenShare(true);
  };
  const handleCloseShare = () => setOpenShare(false);
  const handleOpen = () => {
    if (currentlyPlaying.allData) setOpen(true);
  };
  const handleClose = () => setOpen(false);

  function handleChange(value) {
    setMode(value);
    console.log(`selected ${value}`);
  }

  const useStyles = makeStyles({
    iconbutton: {
      color: "#fff !important",
      "&:hover": {
        backgroundColor: "rgba(72, 72, 72, 0.24)",
        color: "rgb(0, 163, 255) !important",
      },
    },
  });
  console.log(currentlyPlaying.allData);
  const styleUse = useStyles();
  return (
    <Footer className={styles.footerStyle}>
      <Row align="middle" justify="space-between" className={styles.Container}>
        <Col className={styles.innerContainer}>
          <img src={currentlyPlaying.image} className={styles.img} />
          <div className={styles.nameStyles}>
            <p className={styles.songName}>{currentlyPlaying.title}</p>
            <p className={styles.artistName}>{currentlyPlaying.artist}</p>
          </div>
        </Col>
        <Col>
          <div>
            {playing ? (
              <IconButton size="medium" className={styleUse.iconbutton}>
                <PauseIcon onClick={() => setPlaying(false)} className={styles.iconChangeStyle} />
              </IconButton>
            ) : (
              <IconButton size="medium" className={styleUse.iconbutton}>
                <PlayArrowRoundedIcon onClick={() => setPlaying(true)} className={styles.iconChangeStyle} />
              </IconButton>
            )}
          </div>
        </Col>
        <Col>
          <div>
            <IconButton size="medium" className={styleUse.iconbutton}>
              <SkipNextRoundedIcon className={styles.iconChangeStyle} />
            </IconButton>
          </div>
        </Col>
        <SoundControler volume={volume} onChange={(item) => setVolume(item)} />

        <Bar curTime={curTime} duration={duration} onTimeUpdate={(time) => setClickedTime(time)} />
        <Col className={styles.innerContainer}>
          <div style={{ marginLeft: 20 }} className={styles.iconhide}>
            <Select className={styles.selectStyle} onChange={handleChange} value={currentMode} className="selectHegiht">
              {currentModes.map((item) => (
                <Option value={item}>{item}</Option>
              ))}
            </Select>
          </div>
        </Col>
        {/* <Col className={styles.iconhide}>
          <Tooltip title="Add Cart" sx={{ marginLeft: 10 }}>
            <IconButton size="medium" className={styleUse.iconbutton}>
              <AddShoppingCartRoundedIcon />
            </IconButton>
          </Tooltip>
        </Col> */}
        <Col className={styles.iconhide}>
          <Tooltip title="Share">
            <IconButton size="medium" className={styleUse.iconbutton}>
              <ShareRoundedIcon onClick={handleOpenShare} />
            </IconButton>
          </Tooltip>
        </Col>
        <Col className={styles.iconhide}>
          <Tooltip title="Search">
            <IconButton size="medium" className={styleUse.iconbutton}>
              <SearchRoundedIcon />
            </IconButton>
          </Tooltip>
        </Col>
        <Col className={styles.iconhide}>
          <Tooltip title="Add Playlist">
            <IconButton size="medium" className={styleUse.iconbutton}>
              <PlaylistAddRoundedIcon />
            </IconButton>
          </Tooltip>
        </Col>
        <Col className={styles.iconhide}>
          <Tooltip title="Download">
            <IconButton size="medium" className={styleUse.iconbutton}>
              <ArrowDownwardRoundedIcon onClick={handleOpen} />
            </IconButton>
          </Tooltip>
        </Col>

        {currentlyPlaying.allData ? (
          <>
            <Modal
              open={openShare}
              onClose={handleCloseShare}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <SharePopup />
            </Modal>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <DownloadPopup data={currentlyPlaying.allData} />
            </Modal>
          </>
        ) : (
          ""
        )}
      </Row>
    </Footer>
  );
}
