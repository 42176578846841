import { Card, Col, Row } from "antd";
import React, { useState, useEffect } from "react";
import CustomSelect from "../../components/CustomSelect";
import CustomTable from "../../components/CustomTable";
import featuredStyles from "../../styles/Featured.module.css";
import styles from "../../styles/SongFinder.module.css";
import CategorizedAlphabetically from "../../components/CategorizedAlphabetically";
import { useSelector } from "react-redux";
import _ from "lodash";

const SongFinder = ({ activeCategory, selected, genre }) => {
  const [data, setData] = useState();
  const [mood, setMood] = useState([]);
  const music = useSelector((state) => state.app.music);

  const handleChange = async (e, value) => {
    console.log(value);
    const finalData = [];
    for (let i = 0; i < music.length; i++) {
      const element = music[i];
      let getData = element.tracks.filter((track) => value.some((v) => v.genre === track.genre));
      getData = getData.map((d) => ({
        ...d,
        albumName: element.albumName,
        artistName: element.artistName,
        id: element.id,
        image: element.albumImage,
      }));
      finalData.push(...getData);
    }
    if (finalData.length > 0) {
      let mood = [];
      finalData.filter((item) => {
        mood.push({ mood: `${item.mood1}` });
        mood.push({ mood: `${item.mood2}` });
      });
      mood = _.uniqBy(mood, "mood");
      setMood(mood);
    }
    setData(finalData);
  };
  const handleMood = async (e, value) => {
    console.log(value);
    const finalData = [];
    for (let i = 0; i < music.length; i++) {
      const element = music[i];
      let getData = await element.tracks.filter((track) =>
        value.some((mood) => Object.values(track).indexOf(mood.mood) > -1)
      );
      getData = await getData.map((track) => {
        finalData.push({
          ...track,
          albumName: element.albumName,
          artistName: element.artistName,
          albumImage: element.albumImage,
          id: element.id,
        });
      });
    }
    console.log(finalData);
    setData(finalData);
  };
  useEffect(() => {
    if (activeCategory) {
      console.log(activeCategory);
      setData(activeCategory);
    }
  }, [activeCategory]);
  return (
    <div className={styles.maincontainer}>
      <div style={{ marginBottom: 200 }}>
        <Row justify={"center"}>
          <Col span={20}>
            <div className={styles.titlecard}>
              <p className={styles.heading}>Find Songs</p>
              <p className={styles.txt}>Search and Filter through songs to find the perfect fit for your project</p>
            </div>
            <Card className={styles.cardStyle}>
              <div>
                <CustomSelect
                  genre={genre}
                  handleChange={handleChange}
                  handleMood={handleMood}
                  moods={mood}
                  selected={selected}
                />
              </div>
            </Card>
            <div style={{ marginTop: 20 }}>
              <p className={styles.heading}>Results</p>
            </div>
            <div style={{ marginTop: 20 }} className={featuredStyles.songtable}>
              <CustomTable data={data} type="music" />
            </div>
            <div className={featuredStyles.songtable} style={{ padding: "5px", marginTop: 20, marginBottom: 20 }}></div>
            <CategorizedAlphabetically />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SongFinder;
