import { StylesProvider } from "@material-ui/core";
import { Card } from "antd";
import React from "react";

const FinderCard = ({heading, image}) => {
  return (
    <div>
      <Card
        style={{
          backgroundColor: "rgba(72, 72, 72, 0.24)",
          borderRadius: 8,
          border: "none",
          padding: 0,
          marginRight: 10,
          marginTop: 10,
        }}
      >
        <p
          style={{
            fontSize: 18,
            fontWeight: "400,",
            textAlign: "center",
            color: "#C4C4C4",
            margin: 0,
          }}
        >
          {heading}
        </p>
        <img
          src={image}
          alt="link"
          style={{ padding: 0, width: 280, borderRadius: 8, marginTop: 10 }}
        />
      </Card>
    </div>
  );
};

export default FinderCard;
