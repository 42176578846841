import React, { useState, useEffect, useRef } from "react";
import { Layout, Slider, Select, Row, Col } from "antd";
import AccountPage from "../AccountPage";
import MusicPage from "../MusicPage";
import { BrowserRouter as Router, Route } from "react-router-dom";
import SFX from "../SFX";
import SingleSong from "../SingleSong";
import Visual from "../Visual";
import AdminPage from "../AdminPage";
import SideNav from "../../components/SideNav";
import Search from "../Search";
import FindSimilar from "../Search/FindSimilar";
import Palyer from "../../components/AudioPlayer/Palyer";
import { auth } from "../../firebase";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/actions/appActions";

const LayoutSide = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        history.push("/");
      } else {
        console.log(user, "user is this");
        dispatch(setUser(user));
      }
    });
  }, []);
  return (
    <Layout style={{ height: "100vh" }}>
      <div
        style={{
          height: "100vh",
          overflowY: "auto",
          backgroundColor: "#212121",
        }}
      >
        <Router>
          <SideNav />

          <Route exact path="/" component={MusicPage} />
          <Route path="/music" component={MusicPage} />
          <Route path="/admin" component={AdminPage} />
          <Route path="/sfx" component={SFX} />
          <Route path="/visual" component={Visual} />
          <Route path="/me" component={AccountPage} />
          <Route path="/search" component={Search} />
          <Route path="/findSimilar" component={FindSimilar} />
          <Route path="/song/:name/:id" component={SingleSong} />
        </Router>
      </div>
      <Palyer />
    </Layout>
  );
};

export default LayoutSide;
