import React, { useEffect } from "react";
import styles from "../../styles/AccountDetails.module.css";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Menu,
  message,
  Dropdown,
  Select,
  Form,
  Checkbox,
} from "antd";
import { RiArrowDropDownFill } from "react-icons/all";
import ButtonM from "@mui/material/Button";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import { makeStyles } from "@material-ui/core/styles";
import PlanModal from "../../components/PlanModal";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { db, auth } from "../../firebase";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
// import SignModal from "../../components/SignModal";
function handleMenuClick(e) {
  message.info("Click on menu item.");
  console.log("click", e);
}

const AccountDetails = () => {
  const [open, setOpen] = React.useState(false);
  const [signOpen, setSignOpen] = React.useState(false);
  const [signInOpen, setSignInOpen] = React.useState(false);
  const [forgotOpen, setForgotOpen] = React.useState(false);
  const [record, setRecord] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const userId = useSelector((state) => state.app.user.uid);
  const [readOnly, setReadOnly] = React.useState(true);
  const [stripeData, setStripeData] = React.useState(null);
  const [plan, setPlan] = React.useState(null);

  const Option = Select;
  const FormItem = Form.Item;
  const [form] = Form.useForm();

  const useStyles = makeStyles({
    button: {
      backgroundColor: "#009AFA !important",
      borderRadius: 6,
      boxShadow: "none !important",
      fontSize: 14,
      height: 40,
      width: 150,
      color: "#fff !important",
      textTransform: "none !important",
      border: "none",
    },
  });
  const styleUse = useStyles();

  const handleUpdateUser = (value) => {
    setLoading(true);
    const data = {
      email: value.email,
      uid: record.id,
      displayName: value.firstname + value.lastname,
    };
    const config = {
      method: "put",
      url: `https://us-central1-creatorjams.cloudfunctions.net/app/user/update`,
      data,
      headers: {},
    };
    axios(config)
      .then(function (response) {
        console.log("res", response);
        db.collection("users")
          .doc(record.id)
          .update({
            firstname: value.firstname,
            lastname: value.lastname,
            email: value.email,
            country: value.country,
            gender: value.gender,
          })
          .then((doc) => {
            message.success("user updated!");
            setLoading(false);
            console.log("user updated!");
          });
      })
      .catch(function (error) {
        message.error(error.response.data.message);
        console.log(`${error.response.data.message}`);
      });
  };

  const fetchUsers = () =>
    new Promise(async (resolve, reject) => {
      db.collection("users")
        .where("id", "==", userId)
        .onSnapshot((snapshot) => {
          snapshot.forEach((doc) => {
            const userData = doc.data();
            setRecord(userData);
            form.setFieldsValue({
              firstname: userData.firstname,
              lastname: userData.lastname,
              email: userData.email,
              id: userData.id,
              password: userData.password,
              country: userData.country,
              gender: userData.gender,
            });

            // Get all active or trialing subscriptions for the customer
            db.collection("users")
              .doc(userData.id)
              .collection("subscriptions")
              .where("status", "in", ["trialing", "active"])
              .onSnapshot(async (snap) => {
                const subscription = snap.docs[0].data();

                if (subscription) {
                  const subId = subscription.items[0].subscription;

                  db.collection("subscriptions")
                    .doc(subId)
                    .collection("invoices")
                    .onSnapshot(async (snap) => {});
                  const priceData = (await subscription.price.get()).data();
                  console.log(priceData);
                  const productData = (await subscription.product.get()).data();
                  setPlan(productData);
                  setStripeData(subscription);
                }
              });
          });
        });
    });

  useEffect(async () => {
    await fetchUsers();
  }, []);
  return (
    <div style={{ marginBottom: 300 }}>
      <Row justify={"center"}>
        <Col span={18}>
          <div>
            <p
              style={{
                fontSize: 28,
                color: "#fff",
                marginTop: 10,
              }}
            >
              Account
            </p>
          </div>
          <Row justify={"space-between"}>
            <Col>
              <Card className={styles.firstCard}>
                <p className={styles.heading}>My Profile</p>
                <Form
                  name="edit-form"
                  form={form}
                  layout="horizontal"
                  onFinish={handleUpdateUser}
                >
                  <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                      <Form.Item
                        name="firstname"
                        rules={[{ required: true }]}
                        label={``}
                        hasFeedback
                      >
                        <Input
                          size="large"
                          placeholder="First Name"
                          className={`${styles.signInputBox}  my-0`}
                          readOnly={readOnly}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ display: "none" }}
                        name="id"
                        rules={[{ required: true }]}
                        label={``}
                        hasFeedback
                      >
                        <Input
                          size="large"
                          placeholder="First Name"
                          className={`${styles.signInputBox} my-0`}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <Form.Item
                        name="lastname"
                        rules={[{ required: true }]}
                        label={``}
                        hasFeedback
                      >
                        <Input
                          size="large"
                          placeholder="last Name"
                          className={`${styles.signInputBox} my-0`}
                          readOnly={readOnly}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    name="email"
                    rules={[{ required: true }, { type: "email" }]}
                    label={``}
                    hasFeedback
                  >
                    <Input
                      size="large"
                      placeholder="Email"
                      className={styles.signInputBox}
                      readOnly={readOnly}
                    />
                  </Form.Item>
                  <Form.Item
                    name="gender"
                    rules={[{ required: true }]}
                    label={``}
                  >
                    <Select
                      readOnly={readOnly}
                      className={styles.btnStyle}
                      placeholder="Select a gender"
                    >
                      <Option value="Male">Male</Option>
                      <Option value="Female">Female</Option>
                      <Option value="Others">Others</Option>
                    </Select>
                  </Form.Item>
                  {/* <div style={{ marginTop: 10 }}>
            <p className={styles.signTxt}>Date of Birth</p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Input
                className={styles.btnStyleDOF}
                placeholder="Date"
                size="large"
                style={{ textAlign: "left", marginRight: 10 }}
              />

              <Dropdown overlay={menu}>
                <Button className={styles.btnStyleDOF}>
                  November
                  <RiArrowDropDownFill size={20} color={"#fff"} style={{ marginLeft: 120 }} />
                </Button>
              </Dropdown>
              <Input
                className={styles.btnStyleDOF}
                placeholder="Year"
                size="large"
                style={{ textAlign: "left", marginLeft: 10 }}
              />
            </div>
          </div> */}
                  {/* <Form.Item name="plan" rules={[{ required: true }]} label={``} hasFeedback>
            <Select className={styles.btnStyle} placeholder="Select a plan">
              {plans.map((item) => (
                <Option value={item.id}>{item.planname}</Option>
              ))}
            </Select>
          </Form.Item> */}
                  <Form.Item
                    name="country"
                    rules={[{ required: true }]}
                    label={``}
                  >
                    <Select
                      readOnly={readOnly}
                      className={styles.btnStyle}
                      placeholder="Country or Region"
                    >
                      {CountryRegionData.map((item) => (
                        <Option value={item[1]}>{item[0]}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {/* <Form.Item
                    label=""
                    name="password"
                    rules={[{ required: true, message: "Please input your password!" }]}
                  >
                    <Input.Password size="large" placeholder="Password" className={styles.signInputBox} />
                  </Form.Item> */}
                  <div style={{ marginTop: 30 }}>
                    {readOnly ? (
                      <Button
                        variant="contained"
                        className={styleUse.button}
                        onClick={(e) => {
                          e.preventDefault();
                          setReadOnly(false);
                        }}
                      >
                        Edit Profile
                      </Button>
                    ) : (
                      <Button
                        htmlType="submit"
                        loading={loading}
                        variant="contained"
                        className={styleUse.button}
                      >
                        Save
                      </Button>
                    )}
                  </div>
                </Form>
              </Card>
            </Col>

            <Col>
              {plan ? (
                <Card className={styles.secondCard}>
                  <p className={styles.heading}>My Plan</p>
                  <div className={styles.smallBox}>
                    <p className={styles.boxHeading}>{plan?.name}</p>
                    <p className={styles.boxTxt}>{plan?.description}</p>
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <ButtonM variant="contained" className={styleUse.button}>
                      Change Plan
                    </ButtonM>
                  </div>
                </Card>
              ) : (
                ""
              )}
              {stripeData ? (
                <Card className={styles.thirdCard}>
                  <p className={styles.heading}>Payment</p>
                  <p className={styles.paymentTxt}>
                    Your next bill is $
                    {(stripeData.items[0].plan.amount / 1000).toFixed(2)} on{" "}
                    {moment
                      .unix(stripeData.current_period_end.seconds)
                      .format("MM/DD/YYYY")}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {/* <CreditCardRoundedIcon
                      sx={{ fontSize: 38, color: "#BCBCBC" }}
                    /> */}
                    {/* <div style={{ marginLeft: 20 }}>
                      <p className={styles.creditTxt}>
                        Your card ending in 5416
                      </p>
                      <p className={styles.creditTxt}>Expires: 08/2023</p>
                    </div> */}
                  </div>
                  {/* <div style={{ marginTop: 20 }}>
                    <ButtonM variant="contained" className={styleUse.button}>
                      Change Details
                    </ButtonM>
                  </div> */}
                </Card>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <PlanModal onOpen={open} onClose={() => setOpen(false)} />
    </div>
  );
};

export default AccountDetails;
