import { Card } from "antd";
import React from "react";
import styles from "./PastDownloadCard.module.css";
import SongRow from "./SongRow";
const PastDownloadCard = ({ category, timePeriod, topSongs }) => {
  return (
    <div>
      <Card className={styles.card}>{topSongs.map((item, i) => (i < 5 ? <SongRow data={item} index={i} /> : ""))}</Card>
    </div>
  );
};

export default PastDownloadCard;
