import { Card, Row, Col } from "antd";
import React from "react";
import styles from "./PlaylistCard.module.css";
import {
  FaSpotify,
  FaApple,
  FaYoutube,
  AiOutlineArrowDown,
  FaAmazon,
} from "react-icons/all";
import musicImg from "../images/music.jpg";
const PlaylistCard = (props) => {
  return (
    <div>
      <Card className={styles.cardStyle}>
        <Row justify={"space-between"}>
          <div className={styles.infocontainer}>
            <Col>
              <p className={styles.smallTxt}>PLAYLIST</p>
              <p className={styles.heading}>{props.data.title}</p>
              <p className={styles.txt}>{props.data.description}</p>
              <div style={{ marginTop: 60 }}>
                <p className={styles.smallTxt}>
                  JAM ON YOUR FAVOURITE PLATFORM
                </p>
                <div className={styles.iconBox}>
                  <a
                    href={props.data.spotifyURL}
                    className={styles.link}
                    target="_blank"
                  >
                    <FaSpotify size={30} color={"#1ED760"} />
                  </a>
                  <a
                    href={props.data.applemusicURL}
                    className={styles.link}
                    target="_blank"
                  >
                    <FaApple size={30} color={"#FC3C44"} />
                  </a>
                  <a
                    href={props.data.youtubeURL}
                    className={styles.link}
                    target="_blank"
                  >
                    <FaYoutube size={30} color={"#FF0000"} />
                  </a>
                  <a
                    href={props.data.amazonURL}
                    className={styles.link}
                    target="_blank"
                  >
                    <FaAmazon size={30} color={"#FFFFFF"} />
                  </a>
                  <a
                    href={props.data.downloadURL}
                    className={styles.link}
                    target="_blank"
                  >
                    <AiOutlineArrowDown size={30} color={"#C4C4C4"} />
                  </a>
                </div>
              </div>
            </Col>
          </div>

          <Col>
            <img
              src={props.data.image}
              alt="Playlist"
              className={styles.playlistImg}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default PlaylistCard;
