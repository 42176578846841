import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  Upload,
} from "antd";
import React from "react";
import styles from "../../styles/Admin.module.css";
import UploadBar from "../../components/UploadBar";
import LUT from "../../components/LUT";
const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}
const props = {
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange({ file, fileList }) {
    if (file.status !== "uploading") {
      console.log(file, fileList);
    }
  },
};

const LUTAdmin = () => {
  return (
    <div style={{ marginBottom: 200 }}>
      <Row justify={"center"}>
        <Col span={18}>
          <div className={styles.container}>
            <p className={styles.heading}>LUT Admin</p>
            <Button
              type="primary"
              size={"large"}
              style={{
                borderRadius: 6,
                fontSize: 16,
                // height: 40,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              Download Database as CSV
            </Button>
          </div>
          <Card className={styles.cardStyle}>
            <p className={styles.cardHeading}>SFX Pack Uploader</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div>
                <p className={styles.Cardtxt}>Pack Name</p>
                <Input
                  size="large"
                  placeholder="Pack Name"
                  className={styles.smallinputBox}
                />
              </div>
              <div style={{ marginLeft: 40 }}>
                <p className={styles.Cardtxt}>Artist Name</p>
                <Input
                  size="large"
                  placeholder="John"
                  className={styles.smallinputBox}
                />
              </div>
            </div>
            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row',alignItems: 'center' }}>
            <div >
              <p className={styles.Cardtxt}>Select Pack Image</p>
              <Upload {...props}>
                <Button className={styles.uploadButton}>Browse file...</Button>
              </Upload>
            </div>
            <div style={{ marginLeft: 220 }}>
              <p className={styles.Cardtxt}>Select Before Image</p>
              <Upload {...props}>
                <Button className={styles.uploadButton}>Browse file...</Button>
              </Upload>
            </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <p className={styles.Cardtxt}>Number of LUTS</p>
              <Select
                defaultValue="3"
                className={styles.btnStyle}
                onChange={handleChange}
              >
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
              </Select>
            </div>
            <div>
              <LUT heading={"LUT 1"} />
            </div>
            <div>
              <LUT heading={"LUT 2"} />
            </div>
            <div>
              <LUT heading={"LUT 3"} />
            </div>
            <div>
              <UploadBar />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LUTAdmin;
