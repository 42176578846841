import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import styles from "./Popup.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Col, Row } from "antd";
import { IconButton } from "@mui/material";

const PlanModal = ({ onOpen, onClose }) => {
  const useStyles = makeStyles({
    button: {
      backgroundColor: "#009AFA !important",
      borderRadius: 6,
      boxShadow: "none !important",
      fontSize: 16,
      fontWeight: "400 !important",
      height: 40,
      width: 150,
      color: "#fff !important",
      textTransform: "none !important",
    },
    whiteButton: {
      backgroundColor: "#F3F3F3 !important",
      borderRadius: 6,
      boxShadow: "none !important",
      fontSize: 16,
      fontWeight: "400 !important",
      height: 40,
      width: 150,
      color: "#000 !important",
      textTransform: "none !important",
    },
    greybutton: {
      backgroundColor: "#48484880 !important",
      borderRadius: 6,
      boxShadow: "none !important",
      fontSize: 16,
      fontWeight: "400 !important",
      height: 40,
      width: 150,
      color: "#fff !important",
      textTransform: "none !important",
    },
  });
  const styleUse = useStyles();

  return (
    <div>
      <Modal
        open={onOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Box className={styles.planBox}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <p className={styles.heading}>Our Plan</p>
              <IconButton onClick={onClose} sx={{ marginLeft: 55}}>
                <CloseIcon
                  sx={{ fontSize: 24, color: "#C4C4C4" }}
                />
              </IconButton>
            </div>
            <Row gutter={60}>
              <Col>
                <div className={styles.innerplanBox}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p className={styles.innerPlanheading}>All Access</p>
                    <p className={styles.smallInnerPlantxt}>
                      Full access to all Music, SFX and LUTS on CreatorJams
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <p
                        className={styles.innerPlanheading}
                        style={{ marginRight: 10 }}
                      >
                        $9
                      </p>
                      <p className={styles.simpleTxtPlan}>/month*</p>
                    </div>
                    <p className={styles.smallInnerPlantxt}>
                      * When you pay US$108/year upfront for one year.
                    </p>
                    <p className={styles.smallInnerPlantxt}>
                      Otherwise US$12 monthly.
                    </p>
                    <Button variant="contained" className={styleUse.button}>
                      Select
                    </Button>
                  </div>
                  <p
                    className={styles.simpleTxtPlan}
                    style={{ color: "#009AFA", marginTop: 20 }}
                  >
                    Unlimited Music
                  </p>

                  <p
                    className={styles.simpleTxtPlan}
                    style={{ color: "#009AFA", marginTop: 5 }}
                  >
                    Unlimited SFX
                  </p>

                  <p
                    className={styles.simpleTxtPlan}
                    style={{ color: "#009AFA", marginTop: 5 }}
                  >
                    Unlimited LUTS
                  </p>

                  <p className={styles.simpleTxtPlan} style={{ marginTop: 5 }}>
                    Universal License
                  </p>

                  <p className={styles.simpleTxtPlan}>
                    Unlimited Downloads + Playlists
                  </p>

                  <p className={styles.simpleTxtPlan} style={{ marginTop: 5 }}>
                    Lifetime use
                  </p>

                  <p className={styles.simpleTxtPlan} style={{ marginTop: 5 }}>
                    New Music + SFX every month!
                  </p>

                  <p className={styles.simpleTxtPlan} style={{ marginTop: 5 }}>
                    Safe for Youtube + Streaming
                  </p>
                </div>
              </Col>
              <Col>
                <div className={styles.innerplanBox}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p className={styles.innerPlanheading}>Music + SFX</p>
                    <p className={styles.smallInnerPlantxt}>
                      Full access to all Music and SFX on CreatorJams
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <p
                        className={styles.innerPlanheading}
                        style={{ marginRight: 10 }}
                      >
                        $7
                      </p>
                      <p className={styles.simpleTxtPlan}>/month*</p>
                    </div>
                    <p className={styles.smallInnerPlantxt}>
                      * When you pay US$84/year upfront for one year.
                    </p>
                    <p className={styles.smallInnerPlantxt}>
                      Otherwise US$9 monthly.
                    </p>
                    <Button
                      variant="contained"
                      className={styleUse.whiteButton}
                    >
                      Select
                    </Button>
                  </div>
                  <p
                    className={styles.simpleTxtPlan}
                    style={{ color: "#009AFA", marginTop: 20 }}
                  >
                    Unlimited Music
                  </p>

                  <p
                    className={styles.simpleTxtPlan}
                    style={{ color: "#009AFA", marginTop: 5 }}
                  >
                    Unlimited SFX
                  </p>

                  <p className={styles.simpleTxtPlan} style={{ marginTop: 5 }}>
                    Universal License
                  </p>

                  <p className={styles.simpleTxtPlan}>
                    Unlimited Downloads + Playlists
                  </p>

                  <p className={styles.simpleTxtPlan} style={{ marginTop: 5 }}>
                    Lifetime use
                  </p>

                  <p className={styles.simpleTxtPlan} style={{ marginTop: 5 }}>
                    New Music + SFX every month!
                  </p>
                </div>
              </Col>
              <Col>
                <div className={styles.innerplanBox}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p className={styles.innerPlanheading}>Music</p>
                    <p className={styles.smallInnerPlantxt}>
                      Full access to all Music on CreatorJams
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <p
                        className={styles.innerPlanheading}
                        style={{ marginRight: 10 }}
                      >
                        $5
                      </p>
                      <p className={styles.simpleTxtPlan}>/month*</p>
                    </div>
                    <p className={styles.smallInnerPlantxt}>
                      * When you pay US$60/year upfront for one year.
                    </p>
                    <p className={styles.smallInnerPlantxt}>
                      Otherwise US$7 monthly.
                    </p>
                    <Button variant="contained" className={styleUse.greybutton}>
                      Select
                    </Button>
                  </div>
                  <p
                    className={styles.simpleTxtPlan}
                    style={{ color: "#009AFA", marginTop: 20 }}
                  >
                    Unlimited Music
                  </p>

                  <p className={styles.simpleTxtPlan} style={{ marginTop: 5 }}>
                    Universal License
                  </p>

                  <p className={styles.simpleTxtPlan}>
                    Unlimited Downloads + Playlists
                  </p>

                  <p className={styles.simpleTxtPlan} style={{ marginTop: 5 }}>
                    Lifetime use
                  </p>

                  <p className={styles.simpleTxtPlan} style={{ marginTop: 5 }}>
                    New Music every month!
                  </p>

                  <p className={styles.simpleTxtPlan} style={{ marginTop: 5 }}>
                    Safe for Youtube + Streaming
                  </p>
                </div>
              </Col>
            </Row>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default PlanModal;
