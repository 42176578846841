import React from "react";
import Stack from "@mui/material/Stack";
import { Button, Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import albumImg from "../../images/music.jpg";
import styles from "../../styles/SearchFinderSimilar.module.css";
import CustomTable from "../../components/CustomTable";

const FindSimilar = () => {
    const history = useHistory();
  return (
    <div style={{marginTop: 40}}>
      <Row justify={"center"}>
        <Col span={18}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <Stack spacing={2} direction="row" alignItems="center">
              <img src={albumImg} alt="Album" className={styles.albumImg} />
              <div>
                <p className={styles.heading}>
                  Songs similar to Artist - Song Name
                </p>
                <p className={styles.txt}>
                  Based on genre, moods, instrumentation, tempo and key.
                </p>
              </div>
            </Stack>
            <Button
              type="primary"
              size={"large"}
              onClick={() => history.goBack()}
              className={styles.btnStyle}
              style={{ marginLeft: 20 }}
            >
              <AiOutlineArrowLeft
                size={18}
                color={"#fff"}
                style={{ marginRight: 10 }}
              />
              Back to Featured
            </Button>
          </div>
          <div style={{ marginTop: 40 }}>
            <CustomTable />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FindSimilar;
