import React from "react";
import { Dropdown, Input, Button, Menu, message, Modal } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import logo from "../../images/logo.svg";
import styles from "../Popup.module.css";
import ButtonMu from "@mui/material/Button";

export default function ForgetModal({ onOpen, onClose }) {
  const useStyles = makeStyles({
    button: {
      backgroundColor: "#009AFA !important",
      borderRadius: "52px !important",
      boxShadow: "none !important",
      fontSize: "24px !important",
      fontWeight: "400 !important",
      height: 75,
      width: 300,
      color: "#fff !important",
      textTransform: "none !important",
    },
  });
  const styleUse = useStyles();
  return (
    <Modal onCancel={onClose} visible={onOpen} footer={null} closable={false} width={894} centered={true}>
      <Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <img src={logo} alt="Logo" width={72} height={70} />
          <IconButton onClick={onClose} sx={{ marginLeft: 40 }}>
            <CloseIcon sx={{ fontSize: 24, color: "#C4C4C4" }} />
          </IconButton>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <p className={styles.heading}>Forgot Password</p>
        </div>

        <div>
          <Input size="large" placeholder="Email" className={styles.signInputBox} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 40,
          }}
        >
          <ButtonMu variant="contained" className={styleUse.button}>
            Submit
          </ButtonMu>
          <p className={styles.signTxt} style={{ marginTop: 20 }}>
            If there is an account with that email, we’ll be in touch with a reset link soon!
          </p>
        </div>
      </Box>
    </Modal>
  );
}
