import React from "react";
import { Col, Row, Modal, Button, Slider } from "antd";
import ReactCompareImage from "react-compare-image";

export default function LutModal({ isModalVisible, packName, activeLut, handleCancel }) {
  return (
    <Modal
      width={800}
      title={`${packName} - ${activeLut.lutName}`}
      visible={isModalVisible}
      centered={true}
      footer={null}
      bodyStyle={{ backgroundColor: "#242424", color: "#FFFFFF" }}
      onCancel={handleCancel}
    >
      <div style={{ position: "relative" }}>
        <ReactCompareImage
          leftImageCss={{ filter: "grayscale(100%)" }}
          leftImage={activeLut.afterImage}
          rightImage={activeLut.afterImage}
        />
        <span style={{ position: "absolute", bottom: "0", left: "5px" }}>Before</span>
        <span style={{ position: "absolute", bottom: "0", right: "5px" }}>After</span>
      </div>
      <p style={{ fontSize: 18, fontWeight: "400", textAlign: "center", marginTop: 10 }}>LUT Intensity: 92%</p>

      <div>
        <Slider defaultValue={0} trackStyle={{ backgroundColor: "#C4C4C4" }} />
      </div>
      <div style={{ display: "flex", justifyContent: "space-around", marginTop: 20 }}>
        {/* <Button
          type="primary"
          size={"large"}
          style={{
            borderRadius: 6,
            fontSize: 16,
            paddingLeft: 30,
            paddingRight: 30,
          }}
        >
          Add to Cart
        </Button> */}
        <Button
          type="primary"
          size={"large"}
          style={{
            borderRadius: 6,
            fontSize: 16,
            paddingLeft: 30,
            paddingRight: 30,
          }}
        >
          Download
        </Button>
      </div>
    </Modal>
  );
}
