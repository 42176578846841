import { Col, Row } from "antd";
import React, { useState, useEffect } from "react";
import styles from "../../styles/LUTFinder.module.css";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/all";
import FinderCard from "../../components/FinderCard";
import actionImg from "../../images/action.jpg";
import travelImg from "../../images/travel.jpg";
import droneImg from "../../images/drone.jpg";
import vintageImg from "../../images/vinatge.jpg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";

// const Data = [
//   { id: 1, headingName: "Action", img: actionImg },
//   { id: 2, headingName: "Atmospheric", img: travelImg },
//   { id: 3, headingName: "Blues", img: droneImg },
//   { id: 4, headingName: "Black and White", img: actionImg },
//   { id: 5, headingName: "Blues", img: droneImg },
//   { id: 6, headingName: "Cinematic", img: vintageImg },
//   { id: 7, headingName: "City", img: actionImg },
//   { id: 8, headingName: "Commercial", img: actionImg },
//   { id: 9, headingName: "Dramatic", img: actionImg },
//   { id: 10, headingName: "Drones", img: droneImg },
//   { id: 11, headingName: "Food ", img: actionImg },
//   { id: 12, headingName: "Hollywood", img: vintageImg },
//   { id: 13, headingName: "Landscape", img: actionImg },
//   { id: 14, headingName: "Movie", img: actionImg },
//   { id: 15, headingName: "Music Video", img: travelImg },
//   { id: 16, headingName: "Nature", img: actionImg },
//   { id: 17, headingName: "Neon", img: droneImg },
//   { id: 18, headingName: "Travel", img: travelImg },
//   { id: 19, headingName: "Vintage", img: vintageImg },
//   { id: 20, headingName: "Wedding", img: travelImg },
//   { id: 21, headingName: "Winter", img: actionImg },
// ];

const LUTFinder = () => {
  const [data, setData] = useState(useSelector((state) => state.app.luts));
  const [toggle, setToggle] = useState(false);
  const history = useHistory();
  const sort = () => {
    const newData = _.sortBy(data, ["downloads"]);
    if (toggle) {
      setData(newData);
    } else {
      setData(newData.reverse());
    }
    setToggle(!toggle);
  };

  return (
    <div className={styles.maincontainer}>
      <div style={{ marginBottom: 200 }}>
        <Row justify={"center"}>
          <Col span={20}>
            <div className={styles.titlecard}>
              <p className={styles.heading}>LUT Categories</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className={styles.txt}>Find and preview LUTS to find the perfect fit for your project</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p className={styles.txt}>Sort by: Most Popular</p>
                  <AiOutlineArrowDown
                    size={20}
                    color={"#fff"}
                    style={{
                      marginLeft: 5,
                      cursor: "pointer",
                      transform: `${toggle ? "rotate(180deg)" : ""}`,
                      transition: "500ms all ease-in-out",
                    }}
                    onClick={() => sort()}
                  />
                </div>
              </div>
            </div>
            <Row>
              {data.length > 0
                ? data.map((item) => (
                    <Col
                      onClick={() => history.push({ pathname: "/visual/lutView", state: item })}
                      style={{ cursor: "pointer" }}
                    >
                      <FinderCard heading={item.category} image={item.packImage} />
                    </Col>
                  ))
                : ""}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LUTFinder;
