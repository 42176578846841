import React, { useState } from "react";
import { Layout, Slider, Select, Row, Col } from "antd";
import styles from "../../styles/LayoutSide.module.css";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import VolumeUpRoundedIcon from "@mui/icons-material/VolumeUpRounded";
import VolumeOffRoundedIcon from "@mui/icons-material/VolumeOffRounded";

export default function SoundControler({ volume, onChange }) {
  const onChangeVolume = (value) => {
    onChange(value);
  };
  const useStyles = makeStyles({
    iconbutton: {
      color: "#fff !important",
      "&:hover": {
        backgroundColor: "rgba(72, 72, 72, 0.24)",
        color: "rgb(0, 163, 255) !important",
      },
    },
  });

  const styleUse = useStyles();
  return (
    <>
      <Col>
        <div>
          <IconButton size="medium" className={styleUse.iconbutton}>
            {volume == 0 ? (
              <VolumeOffRoundedIcon
                className={styles.iconChangeStyle}
                onClick={() => onChangeVolume(1)}
              />
            ) : (
              <VolumeUpRoundedIcon
                className={styles.iconChangeStyle}
                onClick={() => onChangeVolume(0)}
              />
            )}
          </IconButton>
        </div>
      </Col>
      <Col className={styles.innerContainer}>
        <div className={styles.sliderBarVol}>
          <Slider
            min={0}
            max={1}
            step={0.1}
            tooltipVisible={false}
            onChange={onChange}
            value={typeof volume === "number" ? volume : 0}
            trackStyle={{ backgroundColor: "#C4C4C4" }}
          />
        </div>
      </Col>
    </>
  );
}
