import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const app = firebase.initializeApp({
  apiKey: "AIzaSyDoJ4fM9kv54-_sC0S2WfB7rX7RJ2mXKGw",
  authDomain: "creatorjams.firebaseapp.com",
  projectId: "creatorjams",
  storageBucket: "creatorjams.appspot.com",
  messagingSenderId: "608160815993",
  appId: "1:608160815993:web:c961bda1d680a32c32ada8",
  measurementId: "G-1R66PLMBLG",
});

export const auth = app.auth();
export const db = app.firestore();
export const storage = app.storage();

export default app;
