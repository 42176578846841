import React from "react";
import { Row, Col } from "antd";
import data from "../data/category.json";

export default function CategorizedAlphabetically() {
  return (
    <Row>
      <Col span={12}>
        <h1 style={{ color: "#fff" }}>All Categories | A-Z</h1>
        {data.map((item) => (
          <>
            <h4
              style={{
                fontSize: "1.2em",
                color: "#fff",
                textDecoration: "underline",
                marginBottom: "0",
                marginTop: "1.5em",
              }}
            >
              {item.title}
            </h4>
            {item.category.map((cate) => (
              <p
                style={{
                  fontSize: "1.2em",
                  color: "#fff",
                  marginBottom: "0",
                }}
              >
                {cate}
              </p>
            ))}
          </>
        ))}
      </Col>
      <Col span={12}>
        <h1 style={{ color: "#fff" }}>All Moods | A-Z</h1>
        {data.map((item) => (
          <>
            <h4
              style={{
                fontSize: "1.2em",
                color: "#fff",
                textDecoration: "underline",
                marginBottom: "0",
                marginTop: "1.5em",
              }}
            >
              {item.title}
            </h4>
            {item.category.map((cate) => (
              <p
                style={{
                  fontSize: "1.2em",
                  color: "#fff",
                  marginBottom: "0",
                }}
              >
                {cate}
              </p>
            ))}
          </>
        ))}
      </Col>
    </Row>
  );
}
