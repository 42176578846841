import React from "react";
import Box from "@mui/material/Box";
import styles from "./Popup.module.css";
import CheckIcon from "@mui/icons-material/Check";
import ToggleButton from "@mui/material/ToggleButton";
import Button from "@mui/material/Button";
import { Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { generateZipFile } from "../utils/fileSaver";
import { db, auth } from "../firebase";
import { useSelector } from "react-redux";
import moment from "moment";

const DownloadPopup = ({ data }) => {
  const [selected, setSelected] = React.useState(false);
  const [quality, setQuality] = React.useState("low");
  const [stems, setStems] = React.useState(["fullmix"]);
  const user = useSelector((state) => state.app.user);

  const useStyles = makeStyles({
    button: {
      backgroundColor: "#009AFA !important",
      borderRadius: 6,
      boxShadow: "none !important",
      fontSize: 14,
      height: 40,
      width: 250,
      color: "#fff !important",
      textTransform: "none !important",
    },
  });
  const styleUse = useStyles();

  const musicUpdateData = async (id, trackId) => {
    let newData;
    await db
      .collection("music")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const itemData = doc.data();
          if (id === doc.id) {
            newData = {
              id: doc.id,
              tracks: itemData.tracks,
            };
          }
        });
      });
    newData.tracks = newData.tracks.map((item) => {
      if (item.trackID == trackId) {
        item = { ...item, downloads: item.downloads + 1, downloadedAt: moment().format("X") };
        // console.log(item.downloads);
        // item.downloads = item.downloads + 1;
      }
      return item;
    });
    let pastDownloads = [];
    await db
      .collection("pastdownloads")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let itemData = doc.data();
          if (user.uid === doc.id) {
            pastDownloads = itemData.pastDownloads;
          }
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
    pastDownloads.push({
      songId: trackId,
      type: "music",
    });
    console.log(pastDownloads);
    if (pastDownloads.length > 1) {
      db.collection("pastdownloads").doc(user.uid).update({ pastDownloads: pastDownloads });
    } else {
      db.collection("pastdownloads").doc(user.uid).set({ pastDownloads: pastDownloads });
    }
    db.collection("music").doc(newData.id).update({
      tracks: newData.tracks,
    });
  };

  const sfxUpdateData = async (id, soundId) => {
    console.log(id);
    console.log(soundId);
    let newData;
    await db
      .collection("SFX")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const itemData = doc.data();
          if (id === doc.id) {
            newData = {
              id: doc.id,
              sounds: itemData.sounds,
            };
          }
        });
      });
    console.log(newData);
    newData.sounds = newData.sounds.map((item) => {
      if (item.soundID == soundId) {
        item = { ...item, downloads: item.downloads + 1, downloadedAt: moment().format("X") };
        // console.log(item.downloads);
        // item.downloads = item.downloads + 1;
      }
      return item;
    });
    let pastDownloads = [];
    await db
      .collection("pastdownloads")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let itemData = doc.data();
          if (user.uid === doc.id) {
            pastDownloads = itemData.pastDownloads;
          }
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
    pastDownloads.push({
      songId: soundId,
      type: "sfx",
    });
    if (pastDownloads.length > 1) {
      db.collection("pastdownloads").doc(user.uid).update({ pastDownloads: pastDownloads });
    } else {
      db.collection("pastdownloads").doc(user.uid).set({ pastDownloads: pastDownloads });
    }
    db.collection("SFX").doc(newData.id).update({
      sounds: newData.sounds,
    });
  };

  const onDownload = () => {
    let tracks = [];
    if (data.type == "music") {
      stems.map((item) => {
        if (item == "drums") {
          if (quality == "low")
            tracks.push({
              name: `${data.trackName}Drums`,
              url: data.drumsmp3,
              extension: "mp3",
            });
          else
            tracks.push({
              name: `${data.trackName}Drums`,
              url: data.drumswav,
              extension: "wav",
            });
        } else if (item == "vocal") {
          if (quality == "low")
            tracks.push({
              name: `${data.trackName}Vocal`,
              url: data.vocalmp3,
              extension: "mp3",
            });
          else
            tracks.push({
              name: `${data.trackName}Vocal`,
              url: data.vocalwav,
              extension: "wav",
            });
        } else if (item == "fullmix") {
          if (quality == "low")
            tracks.push({
              name: `${data.trackName}FullMix`,
              url: data.fullmixmp3,
              extension: "mp3",
            });
          else
            tracks.push({
              name: `${data.trackName}FullMix`,
              url: data.fullmixwav,
              extension: "wav",
            });
        } else if (data == "bass") {
          if (quality == "low")
            tracks.push({
              name: `${data.trackName}Bass`,
              url: data.bassmp3,
              extension: "mp3",
            });
          else
            tracks.push({
              name: `${data.trackName}Bass`,
              url: data.basswav,
              extension: "wav",
            });
        } else if (data == "instruments") {
          if (quality == "low")
            tracks.push({
              name: `${data.trackName}Instrument`,
              url: data.instmp3,
              extension: "mp3",
            });
          else
            tracks.push({
              name: `${data.trackName}Instrument`,
              url: data.instwav,
              extension: "wav",
            });
        }
      });
      generateZipFile(tracks, data.trackName);
      musicUpdateData(data.id, data.trackID);
    } else if (data.type == "sfx") {
      if (quality == "low")
        tracks.push({
          name: `${data.soundName}FullMix`,
          url: data.mp3AudioUrl,
          extension: "mp3",
        });
      else
        tracks.push({
          name: `${data.soundName}FullMix`,
          url: data.wavAudioUrl,
          extension: "wav",
        });
      generateZipFile(tracks, data.soundName);
      sfxUpdateData(data.id, data.soundID);
    }
  };
  return (
    <div>
      <Box className={styles.box} style={{ paddingLeft: 80, paddingRight: 80 }}>
        <p className={styles.heading}>Download Song</p>
        <p className={styles.smallTxt}>Select the files you want to download for this track</p>
        <div className={styles.horizaontalLine}></div>
        <p className={styles.heading}>Quality</p>
        <Stack direction="row" spacing={4} alignItems="center" justifyContent="center">
          <div>
            <p className={styles.txt} style={{ marginBottom: 5 }}>
              Low Quality
            </p>
            <ToggleButton
              value="check"
              selected={quality == "low" ? true : false}
              onChange={() => {
                if (quality == "low") setQuality("");
                else setQuality("low");
              }}
            >
              <div
                style={{
                  paddingRight: 5,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: 20,
                }}
              >
                <p className={styles.txt} style={{ marginLeft: 55 }}>
                  320kbps .mp3
                </p>
                <CheckIcon
                  sx={{
                    marginLeft: 5,
                    color: quality == "low" ? "green" : "white",
                  }}
                />
              </div>
            </ToggleButton>
          </div>
          <div>
            <p className={styles.txt} style={{ marginBottom: 5 }}>
              High Quality
            </p>
            <ToggleButton
              value="check"
              selected={quality == "high" ? true : false}
              onChange={() => {
                if (quality == "high") setQuality("");
                else setQuality("high");
              }}
            >
              <div
                style={{
                  paddingRight: 5,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: 20,
                }}
              >
                <p className={styles.txt} style={{ marginLeft: 55 }}>
                  48KHz .wav
                </p>
                <CheckIcon
                  sx={{
                    marginLeft: 5,
                    color: quality == "high" ? "green" : "white",
                  }}
                />
              </div>
            </ToggleButton>
          </div>
        </Stack>
        {data.type == "music" ? (
          <>
            <div className={styles.horizaontalLine}></div>
            <p className={styles.heading}>Stems</p>
            <Stack direction="row" spacing={4} alignItems="center" justifyContent="center">
              <div>
                <ToggleButton
                  value="check"
                  selected={stems.includes("drums") ? true : false}
                  onChange={() => {
                    if (stems.includes("drums")) setStems(stems.filter((item) => item !== "drums"));
                    else setStems([...stems, "drums"]);
                  }}
                >
                  <div
                    style={{
                      paddingRight: 5,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      height: 20,
                    }}
                  >
                    <p className={styles.txt} style={{ marginLeft: 60 }}>
                      Drums
                    </p>
                    <CheckIcon
                      sx={{
                        marginLeft: 5,
                        color: stems.includes("drums") ? "green" : "white",
                      }}
                    />
                  </div>
                </ToggleButton>
              </div>
              <div>
                <ToggleButton
                  value="check"
                  selected={stems.includes("instruments") ? true : false}
                  onChange={() => {
                    if (stems.includes("instruments")) setStems(stems.filter((item) => item !== "instruments"));
                    else setStems([...stems, "instruments"]);
                  }}
                >
                  <div
                    style={{
                      paddingRight: 5,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      height: 20,
                    }}
                  >
                    <p className={styles.txt} style={{ marginLeft: 35 }}>
                      Instruments
                    </p>
                    <CheckIcon
                      sx={{
                        marginLeft: 5,
                        color: stems.includes("instruments") ? "green" : "white",
                      }}
                    />{" "}
                  </div>
                </ToggleButton>
              </div>
            </Stack>
            <Stack direction="row" spacing={4} alignItems="center" justifyContent="center" marginTop={1}>
              <div>
                <ToggleButton
                  value="check"
                  selected={stems.includes("bass") ? true : false}
                  onChange={() => {
                    if (stems.includes("bass")) setStems(stems.filter((item) => item !== "bass"));
                    else setStems([...stems, "bass"]);
                  }}
                >
                  <div
                    style={{
                      paddingRight: 5,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      height: 20,
                    }}
                  >
                    <p className={styles.txt} style={{ marginLeft: 65 }}>
                      Bass
                    </p>
                    <CheckIcon
                      sx={{
                        marginLeft: 5,
                        color: stems.includes("bass") ? "green" : "white",
                      }}
                    />{" "}
                  </div>
                </ToggleButton>
              </div>
              <div>
                <ToggleButton
                  value="check"
                  selected={stems.includes("vocal") ? true : false}
                  onChange={() => {
                    if (stems.includes("vocal")) setStems(stems.filter((item) => item !== "vocal"));
                    else setStems([...stems, "vocal"]);
                  }}
                >
                  <div
                    style={{
                      paddingRight: 5,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      height: 20,
                    }}
                  >
                    <p className={styles.txt} style={{ marginLeft: 60 }}>
                      Vocals
                    </p>
                    <CheckIcon
                      sx={{
                        marginLeft: 5,
                        color: stems.includes("vocal") ? "green" : "white",
                      }}
                    />{" "}
                  </div>
                </ToggleButton>
              </div>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="center" marginTop={1}>
              <div>
                <ToggleButton
                  value="check"
                  selected={stems.includes("fullmix") ? true : false}
                  onChange={() => {
                    if (stems.includes("fullmix")) setStems(stems.filter((item) => item !== "fullmix"));
                    else setStems([...stems, "fullmix"]);
                  }}
                >
                  <div
                    style={{
                      paddingRight: 5,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      height: 20,
                    }}
                  >
                    <p className={styles.txt} style={{ marginLeft: 55 }}>
                      Full Mix
                    </p>
                    <CheckIcon
                      sx={{
                        marginLeft: 5,
                        color: stems.includes("fullmix") ? "green" : "white",
                      }}
                    />{" "}
                  </div>
                </ToggleButton>
              </div>
            </Stack>
          </>
        ) : (
          ""
        )}

        <div className={styles.horizaontalLine}></div>
        <Stack direction="row" alignItems="center" justifyContent="center" marginTop={1}>
          <Button variant="contained" className={styleUse.button} size="large" onClick={() => onDownload()}>
            Download
          </Button>
        </Stack>
      </Box>
    </div>
  );
};

export default DownloadPopup;
