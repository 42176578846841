import { Col, Input, Row } from "antd";
import React, { useState } from "react";
import styles from "../../styles/Search.module.css";
import SearchIcon from "@mui/icons-material/Search";
import Songs from "./Songs";
import SFX from "./SFX";
import Visual from "./Visual";
import { db } from "../../firebase";
import axios from "axios";

const Search = () => {
  const [toggle, setToggle] = useState("song");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sfx, setSfx] = useState([]);
  const [music, setMusic] = useState([]);
  const [musicLength, setMusicLength] = useState(0);
  const [sfxLength, setSfxLength] = useState(0);
  const search = async () => {
    const config = {
      method: "get",
      url: `https://us-central1-creatorjams.cloudfunctions.net/search?text=${encodeURIComponent(searchKeyword)}`,
      headers: {},
    };
    axios(config).then(function (response) {
      console.log("res", response);
      setSfx(response.data.results.sfx);
      setMusic(response.data.results.music);
    });
  };

  return (
    <div>
      <Row justify="center" style={{ marginTop: 20 }}>
        <Col span={18}>
          <Input
            size="large"
            placeholder="Search"
            onChange={(e) => setSearchKeyword(e.target.value)}
            prefix={<SearchIcon sx={{ fontSize: 28, color: "#C4C4C4" }} onClick={search} />}
            className={styles.searchInput}
          />
          <div style={{ display: "flex", flexDirection: "row", marginTop: 30 }}>
            <div className={styles.container}>
              <p className={toggle === "song" ? styles.txtActive : styles.txt} onClick={() => setToggle("song")}>
                Songs
              </p>
              <div className={styles.numContainer}>
                <p className={styles.numTxt}>{musicLength}</p>
              </div>
            </div>
            <div className={styles.container}>
              <p className={toggle === "sfx" ? styles.txtActive : styles.txt} onClick={() => setToggle("sfx")}>
                SFX
              </p>
              <div className={styles.numContainer}>
                <p className={styles.numTxt}>{sfxLength}</p>
              </div>
            </div>
            <div className={styles.container}>
              <p className={toggle === "visual" ? styles.txtActive : styles.txt} onClick={() => setToggle("visual")}>
                Visual
              </p>
              <div className={styles.numContainer}>
                <p className={styles.numTxt}>11</p>
              </div>
            </div>
          </div>
          <div className={styles.horizaontalLine}></div>
          {toggle === "song" ? (
            <>
              <Songs
                music={music}
                setMusicLength={setMusicLength}
                // songs={originalSongs.filter((song) => song.title.toLowerCase().includes(searchKeyword.toLowerCase()))}
              />
            </>
          ) : toggle === "sfx" ? (
            <>
              <SFX sfx={sfx} setSfxLength={setSfxLength} />
            </>
          ) : toggle === "visual" ? (
            <>
              <Visual />
            </>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default Search;
