import { Col, Row } from "antd";
import React, { useEffect } from "react";
import PastDownloadCard from "../../components/PastDownloadCard";
import { db } from "../../firebase";
import { useSelector } from "react-redux";
import CustomTable from "../../components/CustomTable";
import featureStyles from "../../styles/Featured.module.css";
import _ from "lodash";
import moment from "moment";

const PastDownloads = () => {
  const [pastDownloads, setPastDownloads] = React.useState([]);
  const [data, setData] = React.useState([]);
  const user = useSelector((state) => state.app.user.uid);
  const fetchData = async () => {
    let newData = [];
    let songs = [];
    await db
      .collection("SFX")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let itemData = doc.data();
          itemData.sounds.map((sound) => {
            newData.push({
              ...sound,
              category: itemData.category,
              artistName: itemData.artistName,
              image: itemData.packImage,
              id: doc.id,
              audioType: "sfx",
            });
          });
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
    await db
      .collection("music")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let itemData = doc.data();
          itemData.tracks.map((track) => {
            newData.push({
              ...track,
              soundID: track.trackID,
              mp3AudioUrl: track.fullmixmp3,
              category: track.genre,
              soundName: track.trackName,
              albumName: itemData.albumName,
              artistName: itemData.artistName,
              albumImage: itemData.albumImage,
              id: itemData.id,
              audioType: "music",
            });
          });
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
    pastDownloads.map((item) => {
      songs.push(item.songId);
    });
    let finalData = await newData.filter((song) =>
      songs.some((item) => item === song.soundID)
    );
    finalData = finalData.map((item) => ({
      ...item,
      downloadedAt: moment(item.downloadedAt, "X").format("MM/DD/YYYY"),
    }));

    finalData = _.groupBy(finalData, "downloadedAt");
    setData(finalData);
  };
  useEffect(() => {
    fetchData();
  }, [pastDownloads]);

  useEffect(() => {
    db.collection("pastdownloads")
      .get()
      .then((querySnapshot) => {
        // let newData = [];

        querySnapshot.forEach((doc) => {
          const itemData = doc.data();
          if (user === doc.id) {
            setPastDownloads(itemData.pastDownloads);
          }
        });
        // setPastDownloads(newData);
      });
  }, []);

  return (
    <div>
      <Row justify={"center"}>
        <Col span={18}>
          <div>
            <p style={{ marginTop: 10, fontSize: 28, color: "#fff" }}>
              Past Downloads
            </p>
          </div>
          {Object.keys(data)
            .reverse()
            .map((item) => (
              <>
                <p
                  style={{
                    fontSize: 20,
                    color: "#bcbcbc",
                    margin: 0,
                    fontWeight: "500",
                    marginTop: 20,
                  }}
                >
                  {item}
                </p>
                <div
                  className={featureStyles.songtable}
                  style={{ marginTop: 30 }}
                >
                  <CustomTable data={data[item]} type="sfx" />
                </div>
              </>
            ))}

          {/* <PastDownloadCard category={'Genre'} timePeriod={'000 BPM | 00:00'}/> */}
          {/* <p
            style={{
              fontSize: 12,
              color: "#bcbcbc",
              margin: 0,
              fontWeight: "500",
              marginTop: 20,
            }}
          >
            16 June 2021
          </p> */}
          {/* <PastDownloadCard category={'Genre'} timePeriod={'000 BPM | 00:00'}/> */}
          {/* <p
            style={{
              fontSize: 12,
              color: "#bcbcbc",
              margin: 0,
              fontWeight: "500",
              marginTop: 20,
            }}
          >
            16 June 2021
          </p> */}
          <div style={{ marginBottom: 120 }}>
            {/* <PastDownloadCard category={'Genre'} timePeriod={'000 BPM | 00:00'}/> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PastDownloads;
