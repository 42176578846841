import { Button, Progress } from "antd";
import React from "react";

const UploadBar = () => {
  return (
    <div >
      <div
        style={{
          marginTop: 30,
          marginBottom: 20,
          width: "100",
          borderRadius: 7,
          height: 6,
          backgroundColor: "#4848483d",
        }}
      ></div>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Button
        type="primary"
        size={"large"}
        style={{
          borderRadius: 6,
          fontSize: 16,
          height: 40,
          width: 150,
          backgroundColor: "#818181",
          borderColor: "#BCBCBC",
        }}
      >
        Upload All
      </Button>
      <p style={{color: '#BCBCBC', fontSize: 16, fontWeight: 400, marginTop: 20}}>Upload Progress</p>
      <Progress percent={62} trailColor={'#4848483D'} strokeColor={'#BCBCBC'}/>
      <Button
        type="primary"
        size={"large"}
        style={{
          borderRadius: 6,
          fontSize: 14,
          height: 40,
          width: 150,
          marginTop: 20
         
        }}
      >
        Done
      </Button>
      </div>
    </div>
  );
};

export default UploadBar;
