import { Col, Row } from "antd";
import React, { useState, useEffect } from "react";
import albumImg from "../../images/music.jpg";
import styles from "../../styles/Featured.module.css";
import TopCard from "../../components/TopCard";
import Stack from "@mui/material/Stack";
import PastDownloadCard from "../../components/PastDownloadCard";
import { useSelector } from "react-redux";
import SongRow from "../../components/SongRow";
import CustomTable from "../../components/CustomTable";
import _ from "lodash";

const FeaturedSFX = ({ setActiveKey, setActiveCategory, setSelected }) => {
  const sfx = useSelector((state) => state.app.sfx);

  // top category start
  let topCategory = [];
  if (sfx.length > 0) {
    for (let i = 0; i < sfx.length; i++) {
      const element = sfx[i];
      let downloads = 0;
      for (let i = 0; i < element.sounds.length; i++) {
        const sound = element.sounds[i];
        downloads = sound.downloads + downloads;
      }
      topCategory.push({ ...element, downloads: downloads });
    }
  }
  topCategory = _.sortBy(topCategory, ["downloads"]).reverse();
  // top category end

  // popular songs start
  let topSongs = [];
  if (sfx.length > 0) {
    sfx.map((category) => {
      category.sounds.map((sound) => {
        topSongs.push({
          ...sound,
          category: category.category,
          artistName: category.artistName,
          image: category.packImage,
          id: category.id,
        });
      });
    });
  }
  topSongs = _.sortBy(topSongs, ["downloads"]).reverse();
  console.log(topSongs);
  // popular songs end

  return (
    <div className={styles.maincontainer}>
      <div style={{ marginBottom: 300 }}>
        <Row justify={"center"}>
          <Col span={20}>
            <div className={styles.titlecard}>
              <Stack spacing={2} direction="row" alignItems="center">
                <img src={albumImg} alt="Album" className={styles.albumImg} />
                <div style={{ marginLeft: 20 }}>
                  <p className={styles.heading}>Popular Sounds</p>
                  <p className={styles.txt}>Based on sounds people have been downloading.</p>
                </div>
              </Stack>
            </div>
            <div className={styles.songtable}>
              <CustomTable data={topSongs.filter((item, i) => (i < 5 ? item : ""))} pagination={false} type="sfx" />
              {/* {topSongs.map((track, i) => (i < 5 ? <SongRow data={track} index={i} /> : ""))} */}
            </div>

            <div style={{ marginTop: 20 }}>
              <TopCard
                setActiveKey={setActiveKey}
                setActiveCategory={setActiveCategory}
                heading={"Top Categories"}
                setSelected={setSelected}
                data={topCategory}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <TopCard heading={"Top Moods"} data={topCategory} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FeaturedSFX;
