import { message } from "antd";
import { SET_USER, SET_LUTS, SET_SFX, SET_MUSIC } from "../constants";

export const setUser = (data) => {
  return function (dispatch) {
    dispatch({
      type: SET_USER,
      payload: data,
    });
  };
};
export const setLutCategory = (data) => {
  return function (dispatch) {
    dispatch({
      type: SET_LUTS,
      payload: data,
    });
  };
};
export const setSfxCategory = (data) => {
  return function (dispatch) {
    dispatch({
      type: SET_SFX,
      payload: data,
    });
  };
};
export const setMusic = (data) => {
  return function (dispatch) {
    dispatch({
      type: SET_MUSIC,
      payload: data,
    });
  };
};
