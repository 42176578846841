import React from 'react'
import { Tabs } from "antd";
import MusicAdmin from './MusicAdmin';
import SFXAdmin from './SFXAdmin';
import LUTAdmin from './LUTAdmin';
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}

const AdminPage = () => {
    return (
        <div>
        <Tabs
          defaultActiveKey="1"
          onChange={callback}
          centered={true}
          // tabBarGutter={350}
          tabBarStyle={{
            backgroundColor: "#171717",
            color: "#BCBCBC",
          }}
          size={"large"} 
        >
          <TabPane tab="Music Admin" key="1"><MusicAdmin /></TabPane>
          <TabPane tab="SFX Admin" key="2"><SFXAdmin/></TabPane>
          <TabPane tab="LUT Admin" key="3"><LUTAdmin /></TabPane>
        </Tabs>
      </div>
    )
}

export default AdminPage
