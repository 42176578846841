import { Card, Row, Col, message } from "antd";
import React from "react";
import styles from "./PlaylistCard.module.css";
import { FaSpotify, FaApple, FaYoutube, AiOutlineArrowDown, FaAmazon } from "react-icons/all";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import musicImg from "../images/music.jpg";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import PlaylistModal from "./PlaylistModal";
import { useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import DownloadPopup from "./DownloadPopup";
import SharePopup from "./SharePopup";
const SongCard = ({ data, rowData }) => {
  const [open, setOpen] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);
  const [playlistOpen, setPlaylistOpen] = React.useState(false);
  const [createPlaylistOpen, setcreatePlaylistOpen] = React.useState(false);
  const [rowRecord, setRowRecord] = React.useState();
  const user = useSelector((state) => state.app.user);
  const useStyles = makeStyles({
    playbutton: {
      backgroundColor: "rgba(72, 72, 72, 0.24) !important",
      color: "#fff !important",
      "&:hover": {
        color: "#00A3FF !important",
      },
    },
    iconbutton: {
      color: "#fff !important",
      "&:hover": {
        backgroundColor: "transparent",
        color: "rgb(0, 163, 255) !important",
      },
    },
  });
  const styleUse = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleOpenShare = () => {
    setOpenShare(true);
  };
  const handleCloseShare = () => setOpenShare(false);
  return (
    <div>
      <Card className={styles.cardStyle}>
        <Row justify={"space-between"}>
          <div className={styles.infocontainer}>
            <Col>
              <p className={styles.smallTxt}>SONG</p>
              <p className={styles.heading}>{data.soundName ? data.soundName : data.trackName ? data.trackName : ""}</p>
              <p className={styles.txt}>{data.artistName}</p>
              <div style={{ marginTop: 60 }}>
                <p className={styles.smallTxt}>
                  {data.category ? data.category : data.genre ? data.genre : ""} | {data.mood1}, {data.mood2}
                </p>
                <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                  <IconButton size="medium" className={styleUse.playbutton} style={{ marginRight: "1em" }}>
                    <PlayArrowRoundedIcon />
                  </IconButton>
                  <div className={`${styles.iconBox}`} style={{ marginTop: "0" }}>
                    <div className={`${styles.link}`}>
                      <IconButton
                        onClick={() => {
                          if (user) {
                            // console.log(record);
                            setRowRecord({ ...rowData.record, type: rowData.type });
                            handleOpenShare();
                          } else {
                            message.error("Please Login First");
                          }
                        }}
                        size="medium"
                        className={styleUse.iconbutton}
                      >
                        <ShareRoundedIcon size={30} />
                      </IconButton>
                    </div>
                    <div className={styles.link}>
                      <IconButton size="medium" className={styleUse.iconbutton}>
                        <SearchRoundedIcon size={30} />
                      </IconButton>
                    </div>
                    <div className={styles.link}>
                      <IconButton
                        onClick={() => {
                          if (user) {
                            // setSongId(text);
                            setPlaylistOpen(true);
                          } else {
                            message.error("Please Login First");
                          }
                        }}
                        size="medium"
                        className={styleUse.iconbutton}
                      >
                        <PlaylistAddRoundedIcon size={30} />
                      </IconButton>
                    </div>
                    <div className={styles.link}>
                      <IconButton
                        onClick={() => {
                          if (user) {
                            // console.log(record);
                            // setRowRecord({ ...rowData.record, type: rowData.type });
                            handleOpen();
                          } else {
                            message.error("Please Login First");
                          }
                        }}
                        size="medium"
                        className={styleUse.iconbutton}
                      >
                        <ArrowDownwardRoundedIcon size={30} />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>

          <Col>
            <img
              src={data.packImage ? data.packImage : data.albumImage ? data.albumImage : ""}
              alt="Playlist"
              className={styles.playlistImg}
            />
          </Col>
        </Row>
      </Card>
      {user ? (
        <>
          <Modal
            open={openShare}
            onClose={handleCloseShare}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <SharePopup type={rowData.type} RowRecord={rowData.record} />
          </Modal>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <DownloadPopup data={{ ...rowData.record, type: rowData.type }} />
          </Modal>{" "}
          <PlaylistModal
            isaddPlaylist={true}
            onClose={() => setPlaylistOpen(false)}
            onOpen={playlistOpen}
            songId={rowData.type === "sfx" ? rowData.record.soundID : rowData.record.trackID}
            type={rowData.type}
            isChange={() => {
              setPlaylistOpen(false);
              setcreatePlaylistOpen(true);
            }}
          />
          <PlaylistModal
            onOpen={createPlaylistOpen}
            onClose={() => setcreatePlaylistOpen(false)}
            isCreatePlaylist={true}
            songId={rowData.type === "sfx" ? rowData.record.soundID : rowData.record.trackID}
            type={rowData.type}
          />{" "}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default SongCard;
