import { Card, Col, Row } from "antd";
import React, { useState } from "react";
import styles from "./VisualCard.module.css";
import LutModal from "./LutModal";
import { useHistory } from "react-router-dom";

const VisualCard = ({ category, setActiveKey }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeLut, setActiveLut] = useState();
  const history = useHistory();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div>
      <Card className={styles.cardStyle}>
        <p
          className={styles.cardHeading}
          onClick={() => {
            history.push({ pathname: "/visual/lutView", state: category });
            setActiveKey("2");
          }}
          style={{ cursor: "pointer" }}
        >
          {category.category}
        </p>
        <Row>
          {category.luts.map((item) => (
            <Col
              onClick={() => {
                setActiveLut(item);
                showModal();
              }}
              style={{ cursor: "pointer" }}
            >
              <img src={item.previewImage} alt="Action" className={styles.imgStyle} />
              <p className={styles.imgTxt}>{item.lutName}</p>
            </Col>
          ))}
        </Row>
      </Card>
      {activeLut ? (
        <LutModal
          isModalVisible={isModalVisible}
          packName={category.packName}
          activeLut={activeLut}
          handleCancel={handleCancel}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default VisualCard;
