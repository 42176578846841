import { Button, Col, Input, Row, Upload } from "antd";
import React from "react";
import styles from "./Track.module.css";


const props = {
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange({ file, fileList }) {
    if (file.status !== "uploading") {
      console.log(file, fileList);
    }
  },
};
const Sound = ({ heading }) => {

  return (
    <div>
      <div className={styles.horizaontalLine}></div>
      <p className={styles.cardHeading}>{heading}</p>
      <div>
        <p className={styles.Cardtxt}>Sound Name</p>
        <Input
          size="large"
          placeholder="Sound Name"
          className={styles.smallinputBox}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 20,
          alignItems: "center",
        }}
      >
        <div>
          <p className={styles.Cardtxt}>Mood 1</p>
          <Input
            size="large"
            placeholder="Mood 1"
            className={styles.smallinputBox}
          />
        </div>
        <div style={{ marginLeft: 40 }}>
          <p className={styles.Cardtxt}>Mood 2</p>
          <Input
            size="large"
            placeholder="Mood 2"
            className={styles.smallinputBox}
          />
        </div>
      </div>
 
      <Row justify={"space-between"} align={"middle"} style={{ marginTop: 40 }}>
        <Row align={"middle"}>
          <Col>
            <p className={styles.Cardtxt}>Select HQ Audio*</p>
          </Col>
          <Col style={{ marginLeft: 60 }}>
            <Upload {...props}>
              <Button className={styles.uploadButton}>Browse file...</Button>
            </Upload>
          </Col>
          <Col style={{ marginLeft: 20 }}>
            <p className={styles.Cardtxt}>filename.wav</p>
          </Col>
        </Row>

        <Row align={"middle"}>
          <Col>
            <p className={styles.Cardtxt}>Select MP3 Audio*</p>
          </Col>
          <Col style={{ marginLeft: 60 }}>
            <Upload {...props}>
              <Button className={styles.uploadButton}>Browse file...</Button>
            </Upload>
          </Col>
          <Col style={{ marginLeft: 20 }}>
            <p className={styles.Cardtxt}>filename.mp3</p>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default Sound;
