import React, { useState, useEffect } from "react";
import {
  Dropdown,
  Input,
  Button,
  Menu,
  message,
  Modal,
  Form,
  Row,
  Col,
  Select,
} from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import logo from "../../images/logo.svg";
import styles from "../Popup.module.css";
import { RiArrowDropDownFill } from "react-icons/all";
import ButtonMu from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { db, auth } from "../../firebase";
import axios from "axios";
import initializeStripe from "../../stripe/initializeStripe";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/actions/appActions";

export default function SignupModal({ onOpen, onClose, onClick }) {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const FormItem = Form.Item;
  const { Option } = Select;
  const dispatch = useDispatch();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  function handleMenuClick(e) {
    message.info("Click on menu item.");
    console.log("click", e);
  }
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Male</Menu.Item>
      <Menu.Item key="2">Female</Menu.Item>
      <Menu.Item key="3">Other</Menu.Item>
    </Menu>
  );
  const useStyles = makeStyles({
    button: {
      backgroundColor: "#009AFA !important",
      borderRadius: "52px !important",
      boxShadow: "none !important",
      fontSize: "24px !important",
      fontWeight: "400 !important",
      height: 75,
      width: 300,
      color: "#fff !important",
      textTransform: "none !important",
      border: "none",
    },
  });
  const styleUse = useStyles();

  const fetchUserName = async () => {
    try {
      db.collection("plans")
        .get()
        .then((querySnapshot) => {
          let newData = [];

          querySnapshot.forEach((doc) => {
            const itemData = doc.data();

            newData.push({
              id: doc.id,
              planname: itemData.planname,
              description: itemData.description,
              lutaccess: itemData.lutaccess,
              monthprice: itemData.monthprice,
              musicaccess: itemData.musicaccess,
              soundaccess: itemData.soundaccess,
              yearprice: itemData.yearprice,
            });
          });
          setPlans(newData);
        });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchUserName();
  }, []);
  const handleAddUser = async (value) => {
    console.log(value);
    const data = {
      email: value.email,
      password: value.password,
      displayName: value.firstname + value.lastname,
    };
    setLoading(true);
    try {
      await axios
        .post(
          "https://us-central1-creatorjams.cloudfunctions.net/app/user/create",
          data
        )
        .then(async (res) => {
          console.log(res);
          await db.collection("users").doc(res.data.uid).set({
            firstname: value.firstname,
            lastname: value.lastname,
            email: value.email,
            id: res.data.uid,
            password: value.password,
            country: value.country,
            gender: value.gender,
            role: "user",
          });
          console.log("user updated!");

          const checkoutSessionRef = await db
            .collection("users")
            .doc(res.data.uid)
            .collection("checkout_sessions")
            .add({
              price: "price_1JmD3aJ3KVzVPrmSSY4LdnaZ",
              success_url: window.location.origin,
              cancel_url: window.location.origin,
            });

          // Wait for the CheckoutSession to get attached by the extension
          checkoutSessionRef.onSnapshot(async (snap) => {
            const { sessionId } = snap.data();
            console.log(sessionId);
            if (sessionId) {
              // We have a session, let's redirect to Checkout
              // Init Stripe
              const stripe = await initializeStripe();
              stripe.redirectToCheckout({ sessionId });
              auth
                .signInWithEmailAndPassword(value.email, value.password)
                .then((authAdmin) => {
                  db.collection("users")
                    .where("email", "==", authAdmin.user.email)
                    .onSnapshot((snapshot) => {
                      snapshot.forEach((doc) => {
                        dispatch(setUser(authAdmin.user));
                        onClose();
                      });
                    });
                });
            }
          });
          // fetchUserName();
          // setLoading(false);
          // setIsModalVisible(false);
        });
    } catch (error) {
      console.log(error);
      message.error(error.response?.data?.message);
      setLoading(false);
    }
  };

  function onChange(e) {
    console.log(e);
    setChecked(!checked);
  }
  return (
    <Modal
      onCancel={onClose}
      visible={onOpen}
      footer={null}
      closable={false}
      width={894}
      centered={true}
    >
      <Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <img src={logo} alt="Logo" width={70} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          <p className={styles.heading}>Set up your CreatorJams Account</p>
        </div>
        <Form name="control-ref" layout="horizontal" onFinish={handleAddUser}>
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="firstname"
                rules={[{ required: true }]}
                label={``}
                hasFeedback
              >
                <Input
                  size="large"
                  placeholder="First Name"
                  className={`${styles.signInputBox} my-0`}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="lastname"
                rules={[{ required: true }]}
                label={``}
                hasFeedback
              >
                <Input
                  size="large"
                  placeholder="last Name"
                  className={`${styles.signInputBox} my-0`}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="email"
            rules={[{ required: true }, { type: "email" }]}
            label={``}
            hasFeedback
          >
            <Input
              size="large"
              placeholder="Email"
              className={styles.signInputBox}
            />
          </Form.Item>
          <Form.Item name="gender" rules={[{ required: true }]} label={``}>
            <Select className={styles.btnStyle} placeholder="Select a gender">
              <Option value="Male">Male</Option>
              <Option value="Female">Female</Option>
              <Option value="Others">Others</Option>
            </Select>
          </Form.Item>
          {/* <div style={{ marginTop: 10 }}>
            <p className={styles.signTxt}>Date of Birth</p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Input
                className={styles.btnStyleDOF}
                placeholder="Date"
                size="large"
                style={{ textAlign: "left", marginRight: 10 }}
              />

              <Dropdown overlay={menu}>
                <Button className={styles.btnStyleDOF}>
                  November
                  <RiArrowDropDownFill size={20} color={"#fff"} style={{ marginLeft: 120 }} />
                </Button>
              </Dropdown>
              <Input
                className={styles.btnStyleDOF}
                placeholder="Year"
                size="large"
                style={{ textAlign: "left", marginLeft: 10 }}
              />
            </div>
          </div> */}
          {/* <Form.Item name="plan" rules={[{ required: true }]} label={``} hasFeedback>
            <Select className={styles.btnStyle} placeholder="Select a plan">
              {plans.map((item) => (
                <Option value={item.id}>{item.planname}</Option>
              ))}
            </Select>
          </Form.Item> */}
          <Form.Item name="country" rules={[{ required: true }]} label={``}>
            <Select
              className={styles.btnStyle}
              placeholder="Country or Region"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {CountryRegionData.map((item) => (
                <Option value={item[1]}>{item[0]}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label=""
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              size="large"
              placeholder="Password"
              className={styles.signInputBox}
            />
          </Form.Item>
          <Form.Item
            name="confirm"
            label=""
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              size="large"
              placeholder="Confirm Password"
              className={styles.signInputBox}
            />
          </Form.Item>
          <Form.Item name="remember">
            <Checkbox checked={checked} onClick={onChange} />
            <p className={styles.signTxt} style={{ display: "inline" }}>
              I agree to the CreatorJams
              <u
                style={{
                  color: "#009AFA",
                  marginLeft: 5,
                  marginRight: 5,
                  cursor: "pointer",
                }}
              >
                Terms of Use
              </u>
              and
              <u style={{ color: "#009AFA", marginLeft: 5, cursor: "pointer" }}>
                Privacy Policy
              </u>
            </p>
          </Form.Item>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <Button
              htmlType="submit"
              variant="contained"
              loading={loading}
              className={styleUse.button}
            >
              Create Account
            </Button>
            <p className={styles.signTxt} style={{ marginTop: 20 }}>
              Already have an account?{" "}
              <b
                style={{ color: "#009AFA", cursor: "pointer" }}
                onClick={onClick}
              >
                {" "}
                Sign In{" "}
              </b>
            </p>
          </div>
        </Form>
      </Box>
    </Modal>
  );
}
