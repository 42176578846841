import React from "react";
import { Tabs } from "antd";
import AccountDetails from "./AccountDetails";
import PlayLists from "./PlayLists";
import PastDownloads from "./PastDownloads";


const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const AccountPage = () => {
  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        onChange={callback}
        centered={true}
        tabBarStyle={{
          backgroundColor: "#171717",
          color: "#BCBCBC",
        }}
        size={"large"}
      >
        <TabPane tab="Playlists" key="1"><PlayLists/></TabPane>
        <TabPane tab="Past Downloads" key="2"><PastDownloads/></TabPane>
        <TabPane tab="Account " key="3">
          <AccountDetails />
        </TabPane>
      </Tabs>
    </div>         
  );
};

export default AccountPage;
