import { Box } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import React, { useEffect } from "react";
import styles from "./Popup.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Input, Form, message } from "antd";
import { db, auth } from "../firebase";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const PlaylistModal = ({
  isaddPlaylist,
  isCreatePlaylist,
  isChange,
  onClose,
  onOpen,
  songId,
  type,
}) => {
  const [name, setName] = React.useState("");
  const [playlist, setPlaylist] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const handleChange = (event) => {
    setName(event.target.value);
  };
  const useStyles = makeStyles({
    button: {
      backgroundColor: "#009AFA !important",
      borderRadius: 6,
      boxShadow: "none !important",
      fontSize: 16,
      fontWeight: "400 !important",
      height: 40,
      width: 150,
      color: "#fff !important",
      textTransform: "none !important",
    },

    iconbutton: {
      color: "#fff !important",
      "&:hover": {
        backgroundColor: "rgba(72, 72, 72, 0.24)",
        color: "rgb(0, 163, 255) !important",
      },
    },
    form: {
      height: 40,
      color: "#fff !important",
      fontSize: 16,
      "&:hover": {
        backgroundColor: "rgba(72, 72, 72, 0.24)",
        color: "rgb(0, 163, 255) !important",
      },
    },
    menu: {
      height: 40,
      color: "#fff !important",
      fontSize: 16,
      "&:hover": {
        backgroundColor: "#C4C4C4 !important",
        color: "#000 !important",
      },
    },
  });
  const styleUse = useStyles();
  const user = useSelector((state) => state.app?.user?.uid);
  const handleSubmit = async () => {
    setLoading(true);
    if (name) {
      let newData;
      await db
        .collection("playlists")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            if (name === itemData.playlistName) {
              newData = {
                id: doc.id,
                songs: itemData.songs,
              };
            }
          });
        });
      newData.songs.push({ songId: songId, type: type });

      db.collection("playlists").doc(newData.id).update({
        songs: newData.songs,
      });
      setLoading(false);
      onClose();
      message.success("successfully added!");
    }
  };
  const onFinish = (value) => {
    setLoading(true);
    db.collection("playlists")
      .doc(uuidv4())
      .set({
        albumart: "",
        description: "",
        playlistName: value.playlistName,
        songs: [{ songId: songId, type: type }],
        type: "favorite",
        user: user,
      });
    setLoading(false);
    onClose();
  };
  useEffect(() => {
    let newData = [];
    if (isaddPlaylist) {
      // db.collection("playlists")
      //   .where("user", "==", user)
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       let itemData = doc.data();
      //       newData.push(itemData);
      //     });
      //   })
      //   .catch((error) => {
      //     console.log("Error getting documents: ", error);
      //   });
      db.collection("playlists")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            if (user === itemData.user) {
              // setPlaylist([...playlist, itemData]);
              newData.push(itemData);
            }
          });
        });
    }
    console.log(newData);
    setPlaylist(newData);
  }, []);

  return (
    <div>
      {isaddPlaylist ? (
        <Modal
          open={onOpen}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <Box className={styles.boxShare}>
              <p className={styles.heading}>Add to Playlist</p>
              <div
                className={styles.playContainer}
                style={{ backgroundColor: "transparent" }}
              >
                <FormControl fullWidth className={styleUse.form}>
                  <InputLabel className={styleUse.form}>
                    Playlists Name
                  </InputLabel>
                  <Select
                    className={styleUse.select}
                    value={name}
                    label="PlaylistsName"
                    onChange={handleChange}
                  >
                    {playlist.map((item) => (
                      <MenuItem
                        value={item.playlistName}
                        className={styleUse.menu}
                        style={{ display: "block", padding: 10 }}
                      >
                        {item.playlistName}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value={2} className={styleUse.menu}>
                      2nd Song
                    </MenuItem>
                    <MenuItem value={3} className={styleUse.menu}>
                      3rd Song
                    </MenuItem> */}
                  </Select>
                </FormControl>
                <p className={styles.playTxt} onClick={isChange}>
                  Create new playlist
                </p>
                <Button
                  onClick={handleSubmit}
                  loading={loading}
                  variant="contained"
                  className={styleUse.button}
                >
                  Add to playlist
                </Button>
              </div>
            </Box>
          </div>
        </Modal>
      ) : isCreatePlaylist ? (
        <Modal
          open={onOpen}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <Box className={styles.boxShare}>
              <p className={styles.heading}>Create Playlist</p>
              <div
                className={styles.playContainer}
                style={{ backgroundColor: "transparent" }}
              >
                <Form
                  name="create-playlist"
                  layout="horizontal"
                  onFinish={onFinish}
                  style={{ width: "100%", textAlign: "center" }}
                >
                  <Form.Item
                    name="playlistName"
                    style={{ marginBottom: "0" }}
                    rules={[{ required: true }]}
                    label={``}
                  >
                    <Input
                      size="large"
                      placeholder="Playlist Name"
                      className={`${styles.inputBox} `}
                    />
                  </Form.Item>
                  <Button
                    type="submit"
                    variant="contained"
                    loading={loading}
                    className={styleUse.button}
                  >
                    Create playlist
                  </Button>
                </Form>
              </div>
            </Box>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default PlaylistModal;
