import React from "react";
import { useHistory } from "react-router";
import PopularSong from "../../components/PopularSong";
import TopCard from "../../components/TopCard";
import CustomTable from "../../components/CustomTable";
import _ from "lodash";

const Songs = ({ music, setMusicLength }) => {
  // top Music
  let topMusic = [];
  if (music.length > 0) {
    music.map((album) => {
      album.tracks.map((track) => {
        topMusic.push({
          ...track,
          category: album.albumName,
          artistName: album.artistName,
          image: album.albumImage,
          id: album.id,
        });
      });
    });
  }
  topMusic = _.sortBy(topMusic, ["downloads"]).reverse();

  // top Genres
  let topGenre = [];
  if (music.length > 0) {
    let allGenre = [];
    for (let i = 0; i < music.length; i++) {
      const album = music[i];
      album.tracks.map((track) => {
        allGenre.push({
          ...track,
          albumName: album.albumName,
          artistName: album.artistName,
          albumImage: album.albumImage,
          id: album.id,
        });
      });
    }
    allGenre = _.chain(allGenre)
      .groupBy("genre")
      .map((value, key) => ({ genre: key, genreData: value }))
      .value();
    for (let i = 0; i < allGenre.length; i++) {
      const genre = allGenre[i];
      let downloads = 0;
      for (let i = 0; i < genre.genreData.length; i++) {
        const element = genre.genreData[i];
        downloads = element.downloads + downloads;
      }
      topGenre.push({ ...genre, downloads: downloads });
    }
  }
  topGenre = _.sortBy(topGenre, ["downloads"]).reverse();
  React.useEffect(() => {
    setMusicLength(topMusic.length);
  }, [topMusic]);
  return (
    <div style={{ marginBottom: 200 }}>
      <div style={{ marginTop: 20 }}>
        {/* <PopularSong /> */}
        <CustomTable data={topMusic.filter((item, i) => (i < 5 ? item : ""))} pagination={false} type="music" />
      </div>
      <div style={{ marginTop: 20 }}>
        <TopCard
          data={topGenre}
          heading={"Albums"}
          musicType1={"Hip Hop"}
          musicType2={"Pop"}
          musicType3={"Cinematic"}
          musicType4={"Electronic"}
          musicType5={"Rock"}
          musicType6={"Ambient"}
          musicType7={"Jazz"}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <TopCard
          data={[]}
          heading={"Playlists"}
          musicType1={"Happy"}
          musicType2={"Sad"}
          musicType3={"Epic"}
          musicType4={"Angry"}
          musicType5={"Confused"}
          musicType6={"Old School"}
          musicType7={"Nostalgic"}
        />
      </div>
      {/* <p
        style={{ color: "#fff", fontSize: 24 }}
        onClick={() => history.push("/findSimilar")}
      >
        Link
      </p> */}
    </div>
  );
};

export default Songs;
