import { Card, Col, Row } from "antd";
import React from "react";
import topImg from "../images/top.jpg";
import styles from "./TopCard.module.css";

const TopCard = ({
  heading,
  musicType1,
  musicType2,
  musicType3,
  musicType4,
  musicType5,
  musicType6,
  musicType7,
  data,
  setActiveKey,
  setActiveCategory,
  setSelected,
}) => {
  console.log(data);
  return (
    <div>
      <Card className={styles.topCard}>
        <p className={styles.heading}>{heading}</p>
        <Row style={{ marginLeft: "-1em" }}>
          {data.map((item) => {
            return (
              <Col
                style={{ marginLeft: "0", cursor: "pointer" }}
                onClick={() => {
                  setActiveKey("2");
                  setActiveCategory(item.genreData ? item.genreData : item);
                  setSelected(item.genreData ? item.genreData[0].genre : item.category);
                }}
              >
                <img
                  src={item.packImage ? item.packImage : item.genreData.length > 0 ? item.genreData[0].albumImage : ""}
                  alt="Music"
                  className={styles.img}
                />
                <p className={styles.musicTxt}>{item.genre ? item.genre : item.category}</p>
              </Col>
            );
          })}
          {/* <Col>
            <img src={topImg} alt="Music" className={styles.img} />
            <p className={styles.musicTxt}>{musicType2}</p>
          </Col>
          <Col>
            {" "}
            <img src={topImg} alt="Music" className={styles.img} />
            <p className={styles.musicTxt}>{musicType3}</p>
          </Col>
          <Col>
            {" "}
            <img src={topImg} alt="Music" className={styles.img} />
            <p className={styles.musicTxt}>{musicType4}</p>
          </Col>
          <Col>
            {" "}
            <img src={topImg} alt="Music" className={styles.img} />
            <p className={styles.musicTxt}>{musicType5}</p>
          </Col>
          <Col>
            {" "}
            <img src={topImg} alt="Music" className={styles.img} />
            <p className={styles.musicTxt}>{musicType6}</p>
          </Col>
          <Col>
            {" "}
            <img src={topImg} alt="Music" className={styles.img} />
            <p className={styles.musicTxt}>{musicType7}</p>
          </Col> */}
        </Row>
      </Card>
    </div>
  );
};

export default TopCard;
