import JSZip from "jszip";
import saveAs from "file-saver";

export const generateZipFile = (urls, zipname) => {
  var jszip = new JSZip();

  //Looping through to get the download url for each image
  for (let i = 0; i < urls.length; i++) {
    let photoName = urls[i].name;
    let photoImageURL = urls[i].url;

    let photoImageExtension = urls[i].extension;
    jszip.file(
      `${photoName}.${photoImageExtension}`, //in my case, this produces something like 'my image.jpg'
      downloadUrlAsPromise(photoImageURL) //generates the file we need to download
    );

    //If we have reached the last image in the loop, then generate the zip file
    if (i == urls.length - 1) {
      jszip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, `${zipname}Files.zip`);
      });
    }
  }
};

export const downloadUrlAsPromise = (url) => {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "arraybuffer";
    xhr.onreadystatechange = function (evt) {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(new Error("Error for " + url + ": " + xhr.status));
        }
      }
    };
    xhr.send();
  });
};
