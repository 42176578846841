import { Col, Row } from "antd";
import React, { useEffect } from "react";
import albumImg from "../../images/music.jpg";
import styles from "../../styles/Featured.module.css";
import TopCard from "../../components/TopCard";
import PopularSong from "../../components/PopularSong";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core/styles";
import Stack from "@mui/material/Stack";
import SongRow from "../../components/SongRow";
import CustomTable from "../../components/CustomTable";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import _ from "lodash";

const FeaturedMusic = ({ setActiveKey, setActiveCategory, setSelected }) => {
  const history = useHistory();
  const music = useSelector((state) => state.app.music);

  // top Music
  let topMusic = [];
  if (music.length > 0) {
    music.map((album) => {
      album.tracks.map((track) => {
        topMusic.push({
          ...track,
          category: album.albumName,
          artistName: album.artistName,
          image: album.albumImage,
          id: album.id,
        });
      });
    });
  }
  topMusic = _.sortBy(topMusic, ["downloads"]).reverse();

  // top Genres
  let topGenre = [];
  if (music.length > 0) {
    let allGenre = [];
    for (let i = 0; i < music.length; i++) {
      const album = music[i];
      album.tracks.map((track) => {
        allGenre.push({
          ...track,
          albumName: album.albumName,
          artistName: album.artistName,
          albumImage: album.albumImage,
          id: album.id,
        });
      });
    }
    allGenre = _.chain(allGenre)
      .groupBy("genre")
      .map((value, key) => ({ genre: key, genreData: value }))
      .value();
    for (let i = 0; i < allGenre.length; i++) {
      const genre = allGenre[i];
      let downloads = 0;
      for (let i = 0; i < genre.genreData.length; i++) {
        const element = genre.genreData[i];
        downloads = element.downloads + downloads;
      }
      topGenre.push({ ...genre, downloads: downloads });
    }
  }
  topGenre = _.sortBy(topGenre, ["downloads"]).reverse();

  const useStyles = makeStyles({
    button: {
      fontSize: 24,
      height: 40,
      width: 150,
      color: "#2C2C2C !important",
      textTransform: "none !important",
      "&:hover": {
        backgroundColor: "rgba(72, 72, 72, 0.24) !important",
        color: "rgb(0, 163, 255) !important",
      },
    },
  });
  const styleUse = useStyles();

  // useEffect(() => {
  //   topTracks()
  // }, [])
  return (
    <div className={styles.maincontainer}>
      <div style={{ marginBottom: 300 }}>
        <Row justify={"center"}>
          <Col span={20}>
            <div className={styles.titlecard}>
              <Stack spacing={2} direction="row" alignItems="center">
                <img src={albumImg} alt="Album" className={styles.albumImg} />
                <div>
                  <p className={styles.heading}>Popular Songs</p>
                  <p className={styles.txt}>Based on music people have been listening to.</p>
                </div>
              </Stack>
            </div>
            <div className={styles.songtable}>
              {/* <PopularSong /> */}
              <CustomTable data={topMusic.filter((item, i) => (i < 5 ? item : ""))} pagination={false} type="music" />
              {/* {topMusic.map((track, i) => (i < 5 ? <SongRow data={track} index={i} /> : ""))} */}
            </div>

            <div style={{ marginTop: 20 }}>
              <TopCard
                data={topGenre}
                setActiveKey={setActiveKey}
                setActiveCategory={setActiveCategory}
                setSelected={setSelected}
                heading={"Top Genres"}
              />
            </div>

            <div style={{ marginTop: 20 }}>
              <TopCard data={[]} heading={"Top Moods"} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FeaturedMusic;
