import React, { useState } from "react";
import { Dropdown, Input, Button, Menu, message, Modal, Form } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import logo from "../../images/logo.svg";
import styles from "../Popup.module.css";
import ButtonMu from "@mui/material/Button";
import { db, auth } from "../../firebase";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/actions/appActions";

export default function SignInModal({
  onOpen,
  onClose,
  onClick,
  onForgotClick,
}) {
  const [loading, setLoading] = useState(false);
  const FormItem = Form.Item;
  const dispatch = useDispatch();
  const useStyles = makeStyles({
    button: {
      backgroundColor: "#009AFA !important",
      borderRadius: "52px !important",
      boxShadow: "none !important",
      fontSize: "20px !important",
      fontWeight: "400 !important",
      height: 50,
      width: 200,
      color: "#fff !important",
      textTransform: "none !important",
      border: "none",
    },
  });
  const styleUse = useStyles();
  const onFinish = async (value) => {
    setLoading(true);
    auth
      .signInWithEmailAndPassword(value.email, value.password)
      .then((authAdmin) => {
        db.collection("users")
          .where("email", "==", authAdmin.user.email)
          .onSnapshot((snapshot) => {
            snapshot.forEach((doc) => {
              dispatch(setUser(authAdmin.user));
              setLoading(false);
              onClose();
            });
          });
      })
      .catch((error) => {
        alert(error.message);
        setLoading(false);
      });
  };

  return (
    <Modal
      onCancel={onClose}
      visible={onOpen}
      footer={null}
      closable={false}
      width={600}
      centered={true}
    >
      <Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <img src={logo} alt="Logo" width={70} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <p className={styles.heading}>Sign In to CreatorJams</p>
        </div>
        <Form name="control-ref" layout="horizontal" onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[{ required: true }]}
            label={``}
            hasFeedback
          >
            <Input
              size="large"
              placeholder="Email"
              className={styles.signInputBox}
            />
          </Form.Item>
          <Form.Item
            label=""
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              size="large"
              placeholder="Password"
              className={styles.signInputBox}
            />
          </Form.Item>
          <p className={styles.signTxt} onClick={onForgotClick}>
            <u style={{ color: "#009AFA", cursor: "pointer" }}>
              Forgot you password?
            </u>
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 40,
            }}
          >
            <Button
              htmlType="submit"
              variant="contained"
              loading={loading}
              className={styleUse.button}
            >
              Sign In
            </Button>
            <p className={styles.signTxt} style={{ marginTop: 20 }}>
              Don’t have an account?
              <b
                style={{ color: "#009AFA", cursor: "pointer", marginLeft: 5 }}
                onClick={onClick}
              >
                Create an Account
              </b>
            </p>
          </div>
        </Form>
      </Box>
    </Modal>
  );
}
