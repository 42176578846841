import { Button, Col, Input, Row,   Select, Upload } from "antd";
import React from "react";
import styles from "./Track.module.css";

const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}

const props = {
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange({ file, fileList }) {
    if (file.status !== "uploading") {
      console.log(file, fileList);
    }
  },
};
const Track = ({heading}) => {

  return (
    <div>
      <div className={styles.horizaontalLine}></div>
      <p className={styles.cardHeading}>{heading}</p>
      <div>
        <p className={styles.Cardtxt}>Track Name</p>
        <Input
          size="large"
          placeholder="Track Name"
          className={styles.smallinputBox}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 20,
          alignItems: "center",
        }}
      >
        <div>
          <p className={styles.Cardtxt}>Genre</p>
          <Input
            size="large"
            placeholder="Genre"
            className={styles.smallinputBox}
          />
        </div>
        <div style={{ marginLeft: 40 }}>
          <p className={styles.Cardtxt}>Main Instrument</p>
          <Input
            size="large"
            placeholder="Instrument"
            className={styles.smallinputBox}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 20,
          alignItems: "center",
        }}
      >
        <div>
          <p className={styles.Cardtxt}>Mood 1</p>
          <Input
            size="large"
            placeholder="Mood 1"
            className={styles.smallinputBox}
          />
        </div>
        <div style={{ marginLeft: 40 }}>
          <p className={styles.Cardtxt}>Mood 2</p>
          <Input
            size="large"
            placeholder="Mood 2"
            className={styles.smallinputBox}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 20,
          alignItems: "center",
        }}
      >
        <div>
          <p className={styles.Cardtxt}>BPM</p>
          <Input
            size="large"
            placeholder="BPM"
            className={styles.smallinputBox}
          />
        </div>
        <div style={{ marginLeft: 40 }}>
          <p className={styles.Cardtxt}>Key</p>
          <Input
            size="large"
            placeholder="Key"
            className={styles.smallinputBox}
          />
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <p className={styles.Cardtxt}>Vocals?</p>
        <Select
          defaultValue="No"
          className={styles.btnStyle}
          onChange={handleChange}
        >
          <Option value="Yes">Yes</Option>
          <Option value="No">No</Option>
        </Select>
      </div>
      <Row justify={'space-between'} align={"middle"} style={{marginTop: 20}}>
        <Row align={"middle"}>
          <Col>
            <p className={styles.Cardtxt}>Select HQ Full Mix*</p>
          </Col>
          <Col style={{marginLeft: 80}}>
            <Upload {...props}>
              <Button className={styles.uploadButton}>Browse file...</Button>
            </Upload>
          </Col>
          <Col style={{marginLeft: 20}}>
            <p className={styles.Cardtxt}>filename.wav</p>
          </Col>
        </Row>

        <Row align={"middle"}>
          <Col>
            <p className={styles.Cardtxt}>Select MP3 Full Mix*</p>
          </Col>
          <Col style={{marginLeft: 60}}>
            <Upload {...props}>
              <Button className={styles.uploadButton}>Browse file...</Button>
            </Upload>
          </Col>
          <Col style={{marginLeft: 20}}>
            <p className={styles.Cardtxt}>filename.mp3</p>
          </Col>
        </Row>
      </Row>
      <Row justify={'space-between'} align={"middle"} style={{marginTop: 20}}>
        <Row align={"middle"}>
          <Col>
            <p className={styles.Cardtxt}>Select HQ Drums Stem*</p>
          </Col>
          <Col style={{marginLeft: 40}}>
            <Upload {...props}>
              <Button className={styles.uploadButton}>Browse file...</Button>
            </Upload>
          </Col>
          <Col style={{marginLeft: 20}}>
            <p className={styles.Cardtxt}>filename.wav</p>
          </Col>
        </Row>

        <Row align={"middle"}>
          <Col>
            <p className={styles.Cardtxt}>Select MP3 Drums Stem*</p>
          </Col>
          <Col style={{marginLeft: 40}}>
            <Upload {...props}>
              <Button className={styles.uploadButton}>Browse file...</Button>
            </Upload>
          </Col>
          <Col style={{marginLeft: 20}}>
            <p className={styles.Cardtxt}>filename.mp3</p>
          </Col>
        </Row>
      </Row><Row justify={'space-between'} align={"middle"} style={{marginTop: 20}}>
        <Row align={"middle"}>
          <Col>
            <p className={styles.Cardtxt}>Select HQ Bass Stem*</p>
          </Col>
          <Col style={{marginLeft: 60}}>
            <Upload {...props}>
              <Button className={styles.uploadButton}>Browse file...</Button>
            </Upload>
          </Col>
          <Col style={{marginLeft: 20}}>
            <p className={styles.Cardtxt}>filename.wav</p>
          </Col>
        </Row>

        <Row align={"middle"}>
          <Col>
            <p className={styles.Cardtxt}>Select MP3 Bass Stem*</p>
          </Col>
          <Col style={{marginLeft: 50}}>
            <Upload {...props}>
              <Button className={styles.uploadButton}>Browse file...</Button>
            </Upload>
          </Col>
          <Col style={{marginLeft: 20}}>
            <p className={styles.Cardtxt}>filename.mp3</p>
          </Col>
        </Row>
      </Row><Row justify={'space-between'} align={"middle"} style={{marginTop: 20}}>
        <Row align={"middle"}>
          <Col>
            <p className={styles.Cardtxt}>Select HQ Instrument Stem*</p>
          </Col>
          <Col style={{marginLeft: 15}}>
            <Upload {...props}>
              <Button className={styles.uploadButton}>Browse file...</Button>
            </Upload>
          </Col>
          <Col style={{marginLeft: 20}}>
            <p className={styles.Cardtxt}>filename.wav</p>
          </Col>
        </Row>

        <Row align={"middle"}>
          <Col>
            <p className={styles.Cardtxt}>Select MP3 Instrument Stem*</p>
          </Col>
          <Col style={{marginLeft: 10}}>
            <Upload {...props}>
              <Button className={styles.uploadButton}>Browse file...</Button>
            </Upload>
          </Col>
          <Col style={{marginLeft: 20}}>
            <p className={styles.Cardtxt}>filename.mp3</p>
          </Col>
        </Row>
      </Row><Row justify={'space-between'} align={"middle"} style={{marginTop: 20}}>
        <Row align={"middle"}>
          <Col>
            <p className={styles.Cardtxt}>Select HQ Vocal Stem*</p>
          </Col>
          <Col style={{marginLeft: 50}}>
            <Upload {...props}>
              <Button className={styles.uploadButton}>Browse file...</Button>
            </Upload>
          </Col>
          <Col style={{marginLeft: 20}}>
            <p className={styles.Cardtxt}>filename.wav</p>
          </Col>
        </Row>

        <Row align={"middle"}>
          <Col>
            <p className={styles.Cardtxt}>Select MP3 Vocal Stem*</p>
          </Col>
          <Col style={{marginLeft: 40}}>
            <Upload {...props}>
              <Button className={styles.uploadButton}>Browse file...</Button>
            </Upload>
          </Col>
          <Col style={{marginLeft: 20}}>
            <p className={styles.Cardtxt}>filename.mp3</p>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default Track;
