import { Card, Col, Row } from "antd";
import React, { useState, useEffect } from "react";
import CustomSelect from "../../components/CustomSelect";
import CustomTable from "../../components/CustomTable";
import featuredStyles from "../../styles/Featured.module.css";
import styles from "../../styles/SongFinder.module.css";
import CategorizedAlphabetically from "../../components/CategorizedAlphabetically";
import { useSelector } from "react-redux";
import _ from "lodash";

const SongFinderSFX = ({ activeCategory, selected, genre }) => {
  const [data, setData] = useState();
  const [mood, setMood] = useState([]);
  const sfx = useSelector((state) => state.app.sfx);
  const handleChange = async (e, value) => {
    console.log(value);
    const finalData = [];
    const getData = await sfx.filter((item) => value.some((category) => category.genre === item.category));
    const sortingData = await getData.map((category) => {
      category.sounds.map((sound) =>
        finalData.push({
          ...sound,
          category: category.category,
          artistName: category.artistName,
          id: category.id,
        })
      );
    });
    if (finalData.length > 0) {
      let mood = [];
      finalData.filter((item) => {
        mood.push({ mood: `${item.mood1}` });
        mood.push({ mood: `${item.mood2}` });
      });
      mood = _.uniqBy(mood, "mood");
      setMood(mood);
    }
    console.log(finalData);
    setData(finalData);
  };
  const handleMood = async (e, value) => {
    console.log(value);
    const finalData = [];
    for (let i = 0; i < sfx.length; i++) {
      const element = sfx[i];
      let getData = await element.sounds.filter((sound) =>
        value.some((mood) => Object.values(sound).indexOf(mood.mood) > -1)
      );
      getData = await getData.map((sound) => {
        finalData.push({
          ...sound,
          category: element.category,
          artistName: element.artistName,
          image: element.packImage,
          id: element.id,
        });
      });
    }
    console.log(finalData);
    setData(finalData);
  };
  useEffect(() => {
    if (activeCategory) {
      const finalData = [];
      if (activeCategory.sounds) {
        activeCategory.sounds.map((sound) =>
          finalData.push({
            ...sound,
            category: activeCategory.category,
            artistName: activeCategory.artistName,
            id: activeCategory.id,
          })
        );
      }
      setData(finalData);
    }
  }, [activeCategory]);
  return (
    <div className={styles.maincontainer}>
      <div style={{ marginBottom: 200 }}>
        <Row justify={"center"}>
          <Col span={20}>
            <div className={styles.titlecard}>
              <p className={styles.heading}>Find Sounds</p>
              <p className={styles.txt}>Search and Filter through sounds to find the perfect fit for your project</p>
            </div>
            <Card className={styles.cardStyle}>
              <div>
                <CustomSelect
                  handleChange={handleChange}
                  handleMood={handleMood}
                  moods={mood}
                  selected={selected}
                  genre={genre}
                />
              </div>
            </Card>
            <div style={{ marginTop: 20 }}>
              <p className={styles.heading}>Results</p>
            </div>
            <div style={{ marginTop: 20 }} className={featuredStyles.songtable}>
              <CustomTable data={data} type="sfx" />
            </div>
            <div className={featuredStyles.songtable} style={{ padding: "5px", marginTop: 20, marginBottom: 20 }}></div>
            <CategorizedAlphabetically />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SongFinderSFX;
