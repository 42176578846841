import { Col, Row } from "antd";
import React, { useEffect } from "react";
import styles from "../../styles/PlayLists.module.css";
import featureStyles from "../../styles/Featured.module.css";
import Button from "@mui/material/Button";
import IconButton from "@material-ui/core/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import AddShoppingCartRoundedIcon from "@mui/icons-material/AddShoppingCartRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import CustomTable from "../../components/CustomTable";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@material-ui/core/styles";
import DownloadPopup from "../../components/DownloadPopup";
import PlaylistModal from "../../components/PlaylistModal";
import { db } from "../../firebase";
import { useSelector } from "react-redux";

const PlayLists = () => {
  const [name, setName] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [playlist, setPlaylist] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [playlistOpen, setPlaylistOpen] = React.useState(false);
  const [createPlaylistOpen, setcreatePlaylistOpen] = React.useState(false);

  const user = useSelector((state) => state.app?.user?.uid);
  const sfx = useSelector((state) => state.app.sfx);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = async (event) => {
    setName(event.target.value);
    let value = event.target.value;
    let songs = [];
    let newData = [];
    await db
      .collection("SFX")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let itemData = doc.data();
          itemData.sounds.map((sound) => {
            newData.push({
              ...sound,
              category: itemData.category,
              artistName: itemData.artistName,
              image: itemData.packImage,
              id: doc.id,
              audioType: "sfx",
            });
          });
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
    await db
      .collection("music")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let itemData = doc.data();
          itemData.tracks.map((track) => {
            newData.push({
              ...track,
              soundID: track.trackID,
              mp3AudioUrl: track.fullmixmp3,
              category: track.genre,
              soundName: track.trackName,
              albumName: itemData.albumName,
              artistName: itemData.artistName,
              albumImage: itemData.albumImage,
              id: itemData.id,
              audioType: "music",
            });
          });
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
    const list = playlist.find((item) => item.playlistName === value);
    list.songs.map((item) => {
      songs.push(item.songId);
    });
    console.log(newData);
    console.log(songs);
    const finalData = await newData.filter((song) =>
      songs.some((item) => item === song.soundID)
    );
    setData(finalData);
    // for (let i = 0; i < list.songs.length; i++) {
    //   const element = list.songs[i];
    //   const finalData = sfx.sounds.filter((item) => item.soundID === element);
    //   console.log(finalData);
    // }
  };

  const useStyles = makeStyles({
    button: {
      backgroundColor: "#009AFA !important",
      borderRadius: 6,
      boxShadow: "none !important",
      fontSize: 16,
      fontWeight: "400 !important",
      height: 40,
      width: 150,
      color: "#fff !important",
      textTransform: "none !important",
    },
    greyButton: {
      backgroundColor: "#212121 !important",
      borderRadius: 6,
      boxShadow: "none !important",
      borderColor: "#c4c4c4 !important",
      fontSize: 16,
      height: 40,
      color: "#fff !important",
      fontWeight: "400 !important",
      textTransform: "none !important",
    },
    iconbutton: {
      color: "#fff !important",
      "&:hover": {
        backgroundColor: "rgba(72, 72, 72, 0.24)",
        color: "rgb(0, 163, 255) !important",
      },
    },
    form: {
      height: 40,
      color: "#fff !important",
      fontSize: 16,
      "&:hover": {
        backgroundColor: "rgba(72, 72, 72, 0.24)",
        color: "rgb(0, 163, 255) !important",
      },
    },
    menu: {
      height: 40,
      color: "#fff !important",
      fontSize: 16,
      "&:hover": {
        backgroundColor: "#C4C4C4 !important",
        color: "#000 !important",
      },
    },
  });
  const styleUse = useStyles();
  useEffect(() => {
    db.collection("playlists")
      .get()
      .then((querySnapshot) => {
        let newData = [];

        querySnapshot.forEach((doc) => {
          const itemData = doc.data();
          if (user === itemData.user) {
            newData.push(itemData);
          }
        });
        setPlaylist(newData);
      });
  }, []);

  return (
    <div style={{ marginBottom: 200 }}>
      <Row justify={"center"}>
        <Col span={18}>
          <p className={styles.heading}>Playlists</p>
          <Row justify={"space-between"}>
            <Col>
              <Stack direction="row" spacing={2}>
                <Box sx={{ minWidth: 320 }}>
                  <FormControl fullWidth className={styleUse.form}>
                    <InputLabel className={styleUse.form}>
                      Playlists Name
                    </InputLabel>
                    <Select
                      className={styleUse.select}
                      value={name}
                      label="PlaylistsName"
                      onChange={handleChange}
                    >
                      {playlist.length > 0
                        ? playlist.map((item, i) => (
                            <MenuItem
                              value={item.playlistName}
                              className={styleUse.menu}
                              style={{ display: "block", padding: 10 }}
                            >
                              {item.playlistName}
                            </MenuItem>
                          ))
                        : ""}
                      {/* <MenuItem value={2} className={styleUse.menu}>
                        2nd Song
                      </MenuItem>
                      <MenuItem value={3} className={styleUse.menu}>
                        3rd Song
                      </MenuItem> */}
                    </Select>
                  </FormControl>
                </Box>
                <Button
                  variant="contained"
                  className={styleUse.button}
                  onClick={() => setcreatePlaylistOpen(true)}
                >
                  Create Playlist
                </Button>
              </Stack>
            </Col>
            <Col>
              <Stack direction="row" spacing={2}>
                {/* <Button
                  variant="outlined"
                  className={styleUse.greyButton}
                  endIcon={
                    <AddShoppingCartRoundedIcon
                      sx={{ color: "#fff", marginLeft: 1 }}
                    />
                  }
                  onClick={() => setPlaylistOpen(true)}
                >
                  Add playlist to cart
                </Button> */}
                <Button
                  variant="outlined"
                  className={styleUse.greyButton}
                  endIcon={
                    <ArrowDownwardRoundedIcon
                      sx={{ color: "#fff", marginLeft: 1 }}
                    />
                  }
                  onClick={handleOpen}
                >
                  Download Playlist
                </Button>
                <Tooltip title="Delete">
                  <IconButton size="small" className={styleUse.iconbutton}>
                    <DeleteRoundedIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Col>
          </Row>
          <div className={featureStyles.songtable} style={{ marginTop: 30 }}>
            <CustomTable data={data} type="sfx" />
          </div>
        </Col>
      </Row>

      <PlaylistModal
        onOpen={createPlaylistOpen}
        onClose={() => setcreatePlaylistOpen(false)}
        isCreatePlaylist={true}
      />
      <PlaylistModal
        isaddPlaylist={true}
        onClose={() => setPlaylistOpen(false)}
        onOpen={playlistOpen}
        isChange={() => {
          setPlaylistOpen(false);
          setcreatePlaylistOpen(true);
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DownloadPopup />
      </Modal>
    </div>
  );
};

export default PlayLists;
