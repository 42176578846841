import { Stripe, loadStripe } from "@stripe/stripe-js";

let stripePromise: Stripe | null;

const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(
      "pk_test_51Jfi8LJ3KVzVPrmStK5sHP5DcNejQWBhxe0ZKWlHcw4ISq9mpwAPsHDCoyau7uAFw7wXMrtFNc6bwaLOpJzKiRnb00YhfLwA3F"
    );
  }
  return stripePromise;
};

export default initializeStripe;
