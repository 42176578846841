import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import FeaturedMusic from "./FeaturedMusic";
import SongFinder from "./SongFinder";
import StreamMusic from "./StreamMusic";
import { db } from "../../firebase";
import { useSelector, useDispatch } from "react-redux";
import { setMusic } from "../../redux/actions/appActions";
import data from "../../data/category.json";
const { TabPane } = Tabs;

const MusicPage = () => {
  const [activeKey, setActiveKey] = useState();
  const [activeCategory, setActiveCategory] = useState();
  const [selected, setSelected] = useState();

  const [genre, setGenre] = React.useState([]);
  const dispatch = useDispatch();
  const fetchMusic = async () => {
    let newData = [];
    try {
      db.collection("music")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push({
              id: doc.id,
              albumName: itemData.albumName,
              artistName: itemData.artistName,
              albumImage: itemData.albumImage,
              numberTracks: itemData.numberTracks,
              tracks: itemData.tracks,
            });
          });
          dispatch(setMusic(newData));
          console.log(newData, "new data");
        });
    } catch (err) {
      console.error(err);
    }
  };
  function callback(key) {
    setActiveCategory([]);
    setActiveKey(key);
  }
  console.log(activeCategory);

  useEffect(() => {
    fetchMusic();
    let finalgenre = [];
    data.map((item) => item.category.map((genre) => finalgenre.push(genre)));
    setGenre(finalgenre);
  }, []);
  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        activeKey={activeKey}
        onChange={callback}
        centered={true}
        // tabBarGutter={350}
        tabBarStyle={{
          backgroundColor: "#171717",
          color: "#BCBCBC",
        }}
        size={"large"}
      >
        <TabPane tab="Featured" key="1">
          <FeaturedMusic
            setSelected={setSelected}
            setActiveKey={(item) => setActiveKey(item)}
            setActiveCategory={setActiveCategory}
          />
        </TabPane>
        <TabPane tab="Song Finder" key="2">
          <SongFinder genre={genre} selected={selected} activeCategory={activeCategory} />
        </TabPane>
        <TabPane tab="Stream Music" key="3">
          <StreamMusic />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default MusicPage;
