import { useState, useEffect } from "react";

function UseAudioPlayer() {
  const [duration, setDuration] = useState();
  const [curTime, setCurTime] = useState();
  const [playing, setPlaying] = useState(false);
  const [clickedTime, setClickedTime] = useState();
  const [volume, setVolume] = useState(1);
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [queue, setQueue] = useState([]);
  const [currentlyPlaying, setCurrentlyPlaying] = useState({});

  const [currentModes, setCurrentModes] = useState([]);
  const [currentMode, setCurrentMode] = useState(false);

  useEffect(() => {
    if (audioPlayer) audioPlayer.volume = volume;
  }, [volume]);

  useEffect(() => {
    if (audioPlayer) playing ? audioPlayer.play() : audioPlayer.pause();
  }, [playing]);

  useEffect(() => {
    if (audioPlayer) {
      if (clickedTime && clickedTime !== curTime) {
        audioPlayer.currentTime = clickedTime;
        setClickedTime(null);
      }
    }
  }, [clickedTime]);

  const setMode = (mode) => {
    if (audioPlayer) {
      audioPlayer.pause();
      let currentTime = audioPlayer.currentTime;
      setCurrentMode(mode);
      let track = false;
      if (mode == "Full Mix") track = currentlyPlaying.fullmix;
      else if (mode == "Vocals") track = currentlyPlaying.vocal;
      else if (mode == "Instruments") track = currentlyPlaying.inst;
      else if (mode == "Bass") track = currentlyPlaying.bass;
      else if (mode == "Drums") track = currentlyPlaying.drums;
      audioPlayer.src = track;
      audioPlayer.currentTime = currentTime;
      audioPlayer.play();
      setPlaying(true);
    }
  };

  // useEffect(() => {

  // }, [currentMode]);

  useEffect(() => {
    const audio = new Audio();

    setAudioPlayer(audio);

    // state setters wrappers
    const setAudioData = () => {
      setDuration(audio.duration);
      setCurTime(audio.currentTime);
    };

    const setAudioTime = () => setCurTime(audio.currentTime);

    // DOM listeners: update React state on DOM events
    audio.addEventListener("loadeddata", setAudioData);

    audio.addEventListener("timeupdate", setAudioTime);

    window.addEventListener(
      "message",
      (event) => {
        if (event.data.type == "playqueue") {
          setQueue([...queue, event.data]);
          setCurrentlyPlaying(event.data);
          let tempModes = [];
          if (event.data.audiotype == "music") {
            if (event.data.fullmix) tempModes.push("Full Mix");
            if (event.data.vocal) tempModes.push("Vocals");
            if (event.data.inst) tempModes.push("Instruments");
            if (event.data.bass) tempModes.push("Bass");
            if (event.data.drums) tempModes.push("Drums");
          } else if (event.data.audiotype == "sfx")
            tempModes.push("Full Audio");
          setCurrentMode(tempModes[0]);
          setCurrentModes(tempModes);
          setCurrentlyPlaying(event.data);
          audio.src = event.data.fullmix;
          console.log(audio);
          audio.play();
          setPlaying(true);
        }
        // ...
      },
      false
    );

    // effect cleanup
    return () => {
      audio.removeEventListener("loadeddata", setAudioData);
      audio.removeEventListener("timeupdate", setAudioTime);
    };
  }, []);

  // useEffect(() => {
  //   setAudioWrapper((wrapper) => {
  //     wrapper.volume = volume;
  //     return wrapper;
  //   });
  // }, [volume]);

  return {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
    setVolume,
    volume,
    setCurrentMode,
    currentMode,
    currentModes,
    currentlyPlaying,
    setMode,
  };
}

export default UseAudioPlayer;
