import { Row, Col } from "antd";
import React, { useState, useEffect } from "react";
import styles from "../../styles/StreamMusic.module.css";
import logoImage from "../../images/logoImage.png";
import Stack from "@mui/material/Stack";
import PlaylistCard from "../../components/PlaylistCard";
import { db } from "../../firebase";
const StreamMusic = () => {
  const [streamPlaylist, setStreamPlaylist] = useState([]);
  const fetchStreamPlaylist = async () => {
    let newData = [];
    try {
      db.collection("streamPlaylist")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push({
              id: doc.id,
              title: itemData.title,
              description: itemData.description,
              spotifyURL: itemData.spotifyURL,
              amazonURL: itemData.amazonURL,
              youtubeURL: itemData.youtubeURL,
              downloadURL: itemData.downloadURL,
              applemusicURL: itemData.applemusicURL,
              image: itemData.image,
            });
          });
          setStreamPlaylist(newData);
          console.log(newData, "new data");
        });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchStreamPlaylist();
  }, []);

  console.log(streamPlaylist);
  return (
    <div className={styles.maincontainer}>
      <div style={{ marginBottom: 200 }}>
        <Row justify={"center"}>
          <Col span={20}>
            <div className={styles.titlecard}>
              <Stack spacing={2} direction="row" alignItems="center">
                <img src={logoImage} alt="Album" className={styles.logoImg} />
                <div>
                  <p className={styles.heading}>Stream Music</p>
                  <p className={styles.txt}>
                    Music to use in your live streams. No copyright strikes , no
                    DMCA
                  </p>
                </div>
              </Stack>
            </div>
            {streamPlaylist.map((item) => (
              <div style={{ marginTop: 30 }}>
                <PlaylistCard data={item} />
              </div>
            ))}
            {/* <div style={{ marginTop: 30 }}>
              <PlaylistCard
                heading={"Lo-Fi"}
                text={"Copyright free Lo-Fi music for your Twitch streams, YouTube videos, or for all round jamming."}
              />
            </div> */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StreamMusic;
